import React from 'react';
import { Content, ButtonsWrapper } from './statment.styled';
import { i18n } from "../../translate/i18n";

import Wrapper from 'components/wrapper/Wrapper';
import PreviousPage from 'components/previous-page/Previous-Page';
import DatePicker from 'components/date-picker/Date-picker';
import Button from 'components/button/Button';

import { RiDownloadLine } from 'react-icons/ri'
import StatmentTable from './Statment-table/Statment-table';
import { useState } from 'react';
import Dropdown from './dropdown/dropdown';
import { useEffect } from 'react';
import { getCompany } from 'services/company';
import { useSelector } from 'react-redux';
import { downloadStatment, getStatment } from 'services/dd-statment';
import { allMonths } from 'utils/date';
import { useTranslation } from 'react-i18next';

function Statment() {
	const [filter, setFilter] = useState({
		dateRequest: null,
		clientId: null,
	})
	const [data, setData] = useState([]);
	const [summaryData, setSummaryData] = useState({
		totalExecutions: "",
		totalLists: ""
	});
	const { t } = useTranslation();
	const [headerTable, setHeaderTable] = useState([
		{
			id: 1,
			name: t("STATEMENT.PERIOD"),
			sort: false,
		},
		{
			id: 2,
			name: t("STATEMENT.LIST"),
			sort: false,
		},
		{
			id: 3,
			name: t("STATEMENT.REQUEST_BY"),
			sort: false,
		},
		{
			id: 4,
			name: t("STATEMENT.EXECUTIONS"),
			sort: false,
		}
	]);
	const [clients, setClients] = useState([]);
	const conditionData = data.length > 0;
	const { user } = useSelector(state => state);

	// Test user role client
	// const [user, setUser] = useState({
	// 	role: "client", companyId: "9730249a-7e06-46c3-8d7c-120f0ce5587d"
	// })

	useEffect(() => {
		getCompany().then(data => {
			setClients(data);
		})
	}, [])

	const handleFormatTable = async (id) => {

		// Setting the arrow true or false
		setHeaderTable(prevState => (
			prevState.map(key => {
				if (id === key.id) {
					return { ...key, sort: !key.sort }
				} else {
					return { ...key, sort: false }
				}
			})
		));

	}

	// Sort collum
	const handleSortTable = async (collumName, order) => {
		const filters = {
			[t("STATEMENT.PERIOD")]: (a, b) => new Date(a.dateRequest).getTime() - new Date(b.dateRequest).getTime(),
			[t("STATEMENT.LIST")]: (a, b) => a.listName.localeCompare(b.listName),
			[t("STATEMENT.REQUEST_BY")]: (a, b) => a.userName.localeCompare(b.userName),
			[t("STATEMENT.EXECUTIONS")]: (a, b) => a.executions > b.executions ? 1 : -1
		}

		const sortedData = order ? data.sort(filters[collumName]) : data.sort(filters[collumName]).reverse()
		setData(sortedData)
	};

	const handleChange = async (e) => {
		e.preventDefault();
		const newFilter = { ...filter }
		newFilter[e.target.name] = e.target.value
		setFilter(newFilter)
	}

	useEffect(() => {
		if (user.role === "admin") {
			if (filter.dateRequest && filter.companyId) {
				getStatment(new Date(filter.dateRequest), filter.companyId).then((data) => {
					setData(data.items);
					const { totalExecutions, totalLists } = data
					setSummaryData({ totalExecutions, totalLists })
				}).catch((error) => {
					if (error.message === "Request failed with status code 404") {
						setData([]);
					}
				})
			}
		} else {
			if (filter.dateRequest) {
				getStatment(new Date(filter.dateRequest), user.companyId).then((data) => {
					setData(data.items);
					const { totalExecutions, totalLists } = data
					setSummaryData({ totalExecutions, totalLists })
				}).catch((error) => {
					if (error.message === "Request failed with status code 404") {
						setData([]);
					}
				})
			}
		}

	}, [filter, user])

	const handleDownload = async (e) => {
		if (user.role === "admin") {
			if (filter.dateRequest && filter.companyId)
				downloadStatment(new Date(filter.dateRequest), filter.companyId)
		} else {
			if (filter.dateRequest)
				downloadStatment(new Date(filter.dateRequest), user.companyId)
		}
	}

	const formatGetMonth = (date) => {
		const [year, month] = (date || '').split("-");
		const fullDate = new Date(year, month - 1, 1);
		const months = allMonths();

		let monthValue = fullDate.getMonth()
		const lastDay = new Date(fullDate.getFullYear(), monthValue + 1, 0).getDate()

		const result = {
			day: 1,
			month: months[fullDate.getMonth()],
			year: fullDate.getFullYear(),
			lastDay: lastDay
		}


		return result;
	}

	const localizedStartDate = /^en\b/.test(navigator.language || navigator.browserLanguage) ? `${formatGetMonth(filter.dateRequest).month} ${formatGetMonth(filter.dateRequest).day}st` : `${formatGetMonth(filter.dateRequest).day} de ${formatGetMonth(filter.dateRequest).month}`
	const localizedEndDate = /^en\b/.test(navigator.language || navigator.browserLanguage) ? `${formatGetMonth(filter.dateRequest).month} ${formatGetMonth(filter.dateRequest).lastDay}th` : `${formatGetMonth(filter.dateRequest).lastDay} de ${formatGetMonth(filter.dateRequest).month}`

	return (
		<Wrapper>
			<Content>
				<PreviousPage destiny={"/"} label={t("STATEMENT.TITLE_PAGE")} />
				<div className='mainContent'>
					<ButtonsWrapper>
						<DatePicker
							dateType='month'
							name="dateRequest"
							datePickerLabel={t("STATEMENT.FILTER_BY")}
							onChange={handleChange}
							value={filter.dateRequest || ""}
						/>
						{
							user.role === "admin" &&
							<Dropdown
								label={t("USER.INPUTS.COMPANY.LABEL")}
								dropdownPlaceholder={t("USER.INPUTS.COMPANY.PLACEHOLDER")}
								name={'companyId'}
								data={clients}
								onChange={handleChange}
								value={filter.clientId || ""}

							/>
						}
						<Button buttonType={'button'} funcButton={handleDownload} disabledProp={!conditionData}>
							<span className='btnUpload'>
								<RiDownloadLine size={25} />
								<p>{t("STATEMENT.BUTTON_CSV")}</p>
							</span>
						</Button>
					</ButtonsWrapper>
					<div className='executionsWrapper'>
						{
							conditionData && <p>
								<strong>
									{summaryData.totalExecutions}
									{t("STATEMENT.EXECUTION.EXECUTIONS")}
								</strong>{t("STATEMENT.EXECUTION.FROM")}
								<strong>
									{summaryData.totalLists}
									{t("STATEMENT.EXECUTION.LIST")}
								</strong>
								({t("STATEMENT.MONTH_DAY_START")}
								{localizedStartDate} {t("STATEMENT.MONTH_DAY_END")}
								{localizedEndDate} {t("STATEMENT.YEAR_TITLE_LABEL")}
								{formatGetMonth(filter.dateRequest).year})</p>
						}
					</div>
					<div className='statmentTable'>
						<StatmentTable
							data={data}
							headers={headerTable}
							handleFormatTable={handleFormatTable}
							handleSortTable={handleSortTable}
						/>
					</div>
				</div>
			</Content>
		</Wrapper >
	);
}

export default Statment;