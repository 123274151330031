import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import { WrapperCell, WrapperTrable } from './list-dashboard.styled';
import CheckboxCell from 'components/checkbox-cell/CheckboxCell';
import { TableRow, Tooltip } from '@mui/material';
import { formatCnpjCpf } from './../../../utils/document';
import { i18n } from '../../../translate/i18n';
import { useTranslation } from 'react-i18next';


function Row(props) {
	const { row, handleSelectItem } = props;

	return (
		<>
			<TableRow sx={{ '& > *': { border: 'unset' } }}>
				<WrapperCell>
					<CheckboxCell
						checked={row.selected}
						onChange={() => handleSelectItem(row.termId, row.selected)}
						sx={{ width: "42px" }}
					/>
				</WrapperCell>


				<Tooltip title={row.name} placement="bottom">
					<TableCell
						component="th"
						scope="row"
						sx={{ maxWidth: "283px", border: "1px solid rgba(224, 224, 224, 1)" }}>
						<div
							style={{
								display: "block",
								whiteSpace: "nowrap",
								overflow: "hidden",
								textOverflow: "ellipsis",
								textAlign: "left"
							}}
						>
							{row.name}
						</div>
					</TableCell>
				</Tooltip>

				<TableCell
					component="th"
					scope="row"
					sx={{ maxWidth: "154px", border: "1px solid rgba(224, 224, 224, 1)" }}>
					{formatCnpjCpf(row.document.number)}
				</TableCell>

				<TableCell
					component="th"
					scope="row"
					sx={{ maxWidth: "154px", border: "1px solid rgba(224, 224, 224, 1)", color: "#BF0D3E", fontWeight: "bold" }}>
					{row.totalFlags}

				</TableCell>

			</TableRow>
		</>
	);
}

export default function ListDashboard({ rows, handleSelectItem, handleOnChangeCheckbox }) {
	const { t } = useTranslation();
	return (
		<WrapperTrable
			component={Paper}
			elevation={1}
			sx={{
				borderRadius: "20px",
				borderShadow: 'none',
				border: "1px solid rgba(224, 224, 224, 1)",
				boxShadow: "0px 2px 4px #00000029"
			}}
		>
			<Table aria-label="collapsible table">
				<TableHead>
					<TableRow>
						<WrapperCell>
							{/* <CheckboxCell
								checked={rows.length > 0 ? rows.filter(item => item.selected).length === rows.length : false}
								onChange={() => handleOnChangeCheckbox((rows.filter(item => item.selected).length !== rows.length))}
								sx={{ width: "42px" }}
							/> */}
						</WrapperCell>

						<TableCell
							sx={{ border: "1px solid rgba(224, 224, 224, 1)", fontWeight: "bold" }}>
							{t("LIST_DETAIL.LIST_DASHBOARD_TITLE_SUBJECT_NAME")}
						</TableCell>
						<TableCell
							sx={{ maxWidth: "283px", border: "1px solid rgba(224, 224, 224, 1)", fontWeight: "bold" }}>
							{t("LIST_DETAIL.LIST_DASHBOARD_DOC")}
						</TableCell>
						<TableCell
							sx={{ maxWidth: "100px", border: "1px solid rgba(224, 224, 224, 1)", fontWeight: "bold" }}>
							{t("LIST_DETAIL.LIST_DASHBOARD_TITLE_QTD_FLAGS")}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<Row
							key={row.termId}
							row={row}
							checkboxValue={row.selected}
							handleSelectItem={handleSelectItem}
						/>
					))}
				</TableBody>
			</Table>
		</WrapperTrable>
	);
}