import * as React from 'react';

import { Wrapper } from './date-picker.styled'


export default function DatePicker({
	datePickerId,
	datePickerLabel,
	name,
	onChange,
	dateType = "date",
	value
}) {

	return (
		<Wrapper>
			<label htmlFor={datePickerId}>{datePickerLabel}</label>
			<input
				type={dateType}
				id={datePickerId}
				name={name}
				onChange={onChange}
				value={value}
			/>
		</Wrapper>
	);
}