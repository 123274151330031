import styled from "styled-components";

export const Wrapper = styled.div`
	height: 90vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

export const Logo = styled.img`
	@media(max-width: 1400px) {
		width: 404px;
	}
`;

export const MainContent = styled.div`
		display: flex;
		justify-content: space-around;
		align-items: center;
		min-height: 360px;
		margin-top: 80px;

		h1{
			color: var(---primary-kroll-dark-font);
			font-size: var(---tittle-font-size);
			font-weight: normal;
		}
		
		strong{
			color:	var(---primary-kroll);
			font-weight: bold;
		}
`;

export const LeftContent = styled.div`
	width: 560px;
	margin-right: 160px;
`;

export const RightContent = styled.form`
	label{
		margin-top: 15px;
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin:	20px 0;
`;

export const ErrorInput = styled.p`
	margin-top: 10px;
	color:	var(---danger-font-color);
`;

export const ForgotPassword = styled.button`
	background: none;
	text-decoration: underline;
	color: #4D4D4F;
	cursor:pointer;
`;