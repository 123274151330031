import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Wrapper, StyledArrow } from './previous-page.styled';

function PreviousPage({ label, destiny }) {
	const navigate = useNavigate();

	const handleListDeatil = (destiny) => {
		navigate(destiny);
	}

	return (
		<Wrapper>
			<StyledArrow onClick={() => handleListDeatil(destiny)} fontSize={"medium"} />
			<p>{label}</p>
		</Wrapper>
	)
}

export default PreviousPage