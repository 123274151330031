import axios from 'axios';
import store from 'store';
import * as action from 'store/actions';

const createApi = () => {
  const newApi = axios.create()

  const userSession = JSON.parse(localStorage.getItem("@KimProject:user")) || {};

  if (userSession) {
    newApi.defaults.headers.common["Authorization"] = `Bearer ${userSession.token}`;
  }

  newApi.interceptors.request.use(config => {
    store.dispatch(action.showLoader());

    return config;
  }, error => {
    return Promise.reject(error);
  });

  newApi.interceptors.response.use(config => {
    store.dispatch(action.hideLoader());

    return config;
  }, error => {
    if (error.response.status === 401 && userSession.token) {
      localStorage.clear("@KimProject:user");
      window.location.replace("/");
    }

    if (!error.config.hideAlert) {
      store.dispatch(action.showAlert({
        text: error.response.data.message,
        type: 'error'
      }));
    }
    store.dispatch(action.hideLoader());
    setTimeout(() => {
      store.dispatch(action.hideAlert());
    }, 2000)
    return Promise.reject(error);
  });

  return newApi
}

const api = createApi()

export default api;