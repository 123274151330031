import * as yup from 'yup';

import Button from "components/button/Button"
import Wrapper from "components/wrapper/Wrapper"
import Checkbox from "components/checkbox/Checkbox"
import Input from "components/input/input-client/InputClient"
import PreviousPage from "components/previous-page/Previous-Page"
import ModalClient from 'components/modal/modal-client/modal-client';
import DropdownPurchased from "components/dropdown/dropdown-purchased-dd/dropdown-purchased-dd"
import { t } from "i18next";
import { Content } from "./Client.styled"
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import { getCompany, getCompanyId, postCompany, putCompany } from 'services/company';
import { useNavigate } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import { LabelDropdown } from 'components/dropdown/label-dropdown/Label-dropdown';

import * as csc from "country-state-city";
import Alert from 'components/alert/Alert';
import DropdownUser from 'components/dropdown/dropdown-user/dropdown-user';


export const Client = ({ isUpdate }) => {
    const [company, setCompany] = useState([])
    const [purchasedDueDiligence, setPurchasedDueDiligence] = useState(false)
    const [clientData, setClientData] = useState({
        country: '',
        state: '',
        city: '',
    })
    const [editData, setEditData] = useState({
		companyId: null,
    })

    const [modalClientOpen, setModalClientOpen] = useState(false);
    const [countryData, setCountryData] = useState([]);
    const [locationData, setLocationData] = useState("");
    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [verifyFields, setVerifyFields] = useState(false);
    const [alert, setAlert] = useState(false);

    const navigate = useNavigate();

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const validation = yup.object().shape({
        company: yup.string().required(t("CLIENT.INPUTS.COMPANY.VALIDATION.REQUIRED")).max(45, t("CLIENT.INPUTS.COMPANY.VALIDATION.MAX_LENGTH")).min(2, t("CLIENT.INPUTS.COMPANY.VALIDATION.MIN_LENGTH")),
        document: yup.string(),
        email: yup.string().required(t("CLIENT.INPUTS.EMAIL.VALIDATION.REQUIRED")).max(45, t("CLIENT.INPUTS.EMAIL.VALIDATION.MAX_LENGTH")).min(3, t("CLIENT.INPUTS.EMAIL.VALIDATION.MIN_LENGTH")),
        phone: yup.string().matches(phoneRegExp, t("CLIENT.INPUTS.PHONE.VALIDATION.MATCHES")).required(t("CLIENT.INPUTS.PHONE.VALIDATION.REQUIRED")).max(30, t("CLIENT.INPUTS.PHONE.VALIDATION.MAX_LENGTH")).min(4, t("CLIENT.INPUTS.PHONE.VALIDATION.MIN_LENGTH")),
        number: yup.string(),
        district: yup.string(),
        street: yup.string(),
        country: yup.string(),
        state: yup.string(),
        city: yup.string()
    })

    const { register, handleSubmit, watch, setValue, reset, control, formState: { errors } } = useForm({
        resolver: yupResolver(validation)
    });

    const watchAllFields = watch()

    useEffect(() => {
        if (watchAllFields.company && watchAllFields.email &&
            watchAllFields.phone && watchAllFields.quota) {
            setVerifyFields(true);
        }
    }, [watchAllFields])


    const loadData = async () => {
        if (isUpdate) {
            const allCompanies = await getCompany();
            setCompany(allCompanies);
        }
    }

    const handleEditData = (e) => {
        e.preventDefault();
        const newEditData = {...editData}
        newEditData[e.target.name] = e.target.value
        setEditData(newEditData)
    }

    useEffect(() => {

        const getAllCountries = () => {
            let countries = csc.Country.getAllCountries();
            if (countries.length > 0) {
                setCountryData(countries);
            }
        };


        getAllCountries();
        loadData()
    }, []);

    const getAllStates = (stateCode) => {
        const states = csc.State.getStatesOfCountry(stateCode);
        setStateData(states);
        return states
    }

    const getAllCitys = (countryCode, stateCode) => {
        const citys = csc.City.getCitiesOfState(countryCode, stateCode);
        setCityData(citys);
        return citys
    }

    const formatData = (data) => {
        const {
            company,
            document,
            email,
            phone,
            number,
            district,
            quota,
            street,
            country,
            state,
            city
        } = data;

        const result = {
            name: company,
            document: [
                {
                    "type": "cnpj",
                    "number": document
                }
            ],
            phone: [
                {
                    "principal": true,
                    "number": phone
                }
            ],
            email: email,
            location: {
                "country": country,
                "state": state,
                "city": city,
                "district": district,
                "number": number,
                "street": street,
            },
            products: [
                {
                    "name": "Due Diligence",
                    "active": true,
                    "quota": quota
                }
            ]
        };

        return result;
    }

    const onSubmit = (data) => {
        const formatedData = formatData(data);
        const result = isUpdate ? putCompany(editData.companyId, formatedData) : postCompany(formatedData)

        result.then(e => {
            if (e.message === "Company created successfully" || e.message === 'Company edited successfully') {

                setModalClientOpen(true)
                reset();

                setModalClientOpen(true)
                setAlert(true)
                setTimeout(() => {
                    setAlert(false)
                }, "5000")
            }
        })
    }

    const buildClientData = (client) => {
        return {
            document: client?.document[0].number||'',
            email: client?.email,
            id: client?.id,
            phone: client?.phone[0].number||'',
            company: client?.name,
            country: client?.location?.country,
            city: client?.location?.city,
            district: client?.location?.district,
            number: client?.location?.number,
            state: client?.location?.state,
            products: client?.products,
            street: client?.location?.street,
        }
    }

    useEffect(() => {
        const loadDataFromCompanyId = async () => {
            const selectedCompany = await getCompanyId(editData.companyId)
            const company = buildClientData(selectedCompany)
            setEditData({...editData, ...company})

            const fields = Object.keys(company)
            fields.forEach((field) => {
                setValue(field, company[field])
            })

            const countryIsoCode = countryData.find(item => item.name === company.country)?.isoCode
            const states = getAllStates(countryIsoCode)
            setLocationData(countryIsoCode)

            const stateIsoCode = states.find(item => item.name === company.state)?.isoCode
            console.log(countryIsoCode, stateIsoCode)
            getAllCitys(countryIsoCode, stateIsoCode)

            const ddInfo = selectedCompany.products.find(item => item.name === "Due Diligence")
            setPurchasedDueDiligence(ddInfo.active)
            setValue('quota', String(ddInfo.quota))
        }

        if (editData.companyId) {
            loadDataFromCompanyId()
        }
    },[editData.companyId])

    useEffect(() => {
        if (editData.state)
            setValue('state', editData.state)
    },[stateData])

    useEffect(() => {
        console.log(editData.city, cityData)
        if (editData.city)
            setValue('city', editData.city)
    },[cityData])

    const handleSaveButton = async (event) => {
        event.preventDefault();
        handleSubmit(onSubmit)()
    }

    return (
        <>
            <ModalClient
                isOpen={modalClientOpen}
                handleSave={() => navigate('/user', { state: { companyName: watchAllFields.company } })}
                handleClose={() => navigate('/')}
                isUpdate={isUpdate}
            />
            <Alert open={alert} setOpen={setAlert} type="success" text={isUpdate ? t("CLIENT.ALERT.EDIT_TITLE") : t("CLIENT.ALERT.TITLE")} />

            <Wrapper >
                <Content>
                    <PreviousPage label={t(isUpdate ? "CLIENT.EDIT_PREVIOUS_PAGE" : "CLIENT.PREVIOUS_PAGE")} destiny={"/"} />
                    <div className="editClientOptions" style={{ display: isUpdate ? 'block' : 'none' }}>
                        <div className="section">
                            <DropdownUser
                                dropdownPlaceholder={t("USER.INPUTS.COMPANY.PLACEHOLDER")}
                                name={'companyId'}
                                company={company}
                                onChange={handleEditData}
                            />
                        </div>
                    </div>
                    <h1>{t("CLIENT.TITLE")}</h1>
                    <form onSubmit={(event) => handleSaveButton(event)}>
                        <div className='mainContent'>
                            <div className="section">
                                <Input
                                    errors={errors?.company}
                                    register={register}
                                    labelInput={t("CLIENT.INPUTS.COMPANY.LABEL")}
                                    type={'text'}
                                    placeholder={t("CLIENT.INPUTS.COMPANY.PLACEHOLDER")}
                                    name={'company'}
                                    disabled={isUpdate && !editData.companyId}
                                />
                                <Input
                                    errors={errors?.name}
                                    register={register}
                                    labelInput={t("CLIENT.INPUTS.DOCUMENT.LABEL")}
                                    name={'document'}
                                    type={'text'}
                                    placeholder={t("CLIENT.INPUTS.DOCUMENT.PLACEHOLDER")}
                                    disabled={isUpdate && !editData.companyId}
                                />
                                <Input
                                    errors={errors?.email}
                                    register={register}
                                    labelInput={t("CLIENT.INPUTS.EMAIL.LABEL")}
                                    type={'email'}
                                    name={'email'}
                                    placeholder={t("CLIENT.INPUTS.EMAIL.PLACEHOLDER")}
                                    disabled={isUpdate && !editData.companyId}
                                />
                                <Input
                                    errors={errors?.phone}
                                    register={register}
                                    labelInput={t("CLIENT.INPUTS.PHONE.LABEL")}
                                    name={'phone'}
                                    type={'text'}
                                    placeholder={t("CLIENT.INPUTS.PHONE.PLACEHOLDER")}
                                    disabled={isUpdate && !editData.companyId}
                                />
                                <LabelDropdown
                                    errors={errors?.country}
                                    control={control}
                                    label={t("CLIENT.INPUTS.COUNTRY.LABEL")}
                                    name={'country'}
                                    dropdownPlaceholder={t("CLIENT.INPUTS.COUNTRY.PLACEHOLDER")}
                                    handleChange={
                                        (e) => {
                                            const isoCode = countryData.find(item => item.name === e.target.value)?.isoCode
                                            console.log(isoCode)
                                            getAllStates(isoCode)
                                            setLocationData(isoCode)
                                        }
                                    }
                                    // onChange={(e) => {
                                    //     setCityData([]);
                                    //     setStateData([]);
                                    //     setClientData((prevState) => ({
                                    //         ...prevState,
                                    //         country: e.target.value.name,
                                    //         countryInfo: e.target.value
                                    //     }))
                                    // }}
                                    data={countryData || []}
                                    disabled={isUpdate && !editData.companyId}
                                />
                                <LabelDropdown
                                    errors={errors?.state}
                                    label={t("CLIENT.INPUTS.STATE.LABEL")}
                                    name={'state'}
                                    control={control}
                                    dropdownPlaceholder={t("CLIENT.INPUTS.STATE.PLACEHOLDER")}
                                    data={stateData || []}
                                    handleChange={
                                        (e) => {
                                            const isoCode = stateData.find(item => item.name === e.target.value)?.isoCode
                                            getAllCitys(locationData, isoCode)
                                        }
                                    }
                                    // onChange={(e) => {
                                    //     setCityData([]);
                                    //     setClientData((prevState) => ({
                                    //         ...prevState,
                                    //         state: e.target.value.name,
                                    //         stateInfo: e.target.value
                                    //     }))
                                    // }}
                                    disabled={isUpdate && !editData.companyId}
                                />
                                <LabelDropdown
                                    errors={errors?.city}
                                    label={t("CLIENT.INPUTS.CITY.LABEL")}
                                    name={'city'}
                                    control={control}
                                    dropdownPlaceholder={t("CLIENT.INPUTS.CITY.PLACEHOLDER")}
                                    data={cityData || []}
                                    // onChange={(e) => {
                                    //     setClientData((prevState) => ({
                                    //         ...prevState,
                                    //         city: e.target.value.name,
                                    //         cityInfo: e.target.value
                                    //     }))
                                    // }}
                                    disabled={isUpdate && !editData.companyId}
                                />
                                <Input
                                    errors={errors?.district}
                                    register={register}
                                    labelInput={t("CLIENT.INPUTS.DISTRICT.LABEL")}
                                    name={'district'}
                                    type={'text'}
                                    placeholder={t("CLIENT.INPUTS.DISTRICT.PLACEHOLDER")}
                                    disabled={isUpdate && !editData.companyId}
                                />
                                <Input
                                    errors={errors?.street}
                                    register={register}
                                    labelInput={t("CLIENT.INPUTS.STREET.LABEL")}
                                    name={'street'}
                                    type={'text'}
                                    placeholder={t("CLIENT.INPUTS.STREET.PLACEHOLDER")}
                                    disabled={isUpdate && !editData.companyId}
                                />
                                <Input
                                    errors={errors?.number}
                                    register={register}
                                    labelInput={t("CLIENT.INPUTS.NUMBER.LABEL")}
                                    name={'number'}
                                    type={'text'}
                                    placeholder={t("CLIENT.INPUTS.NUMBER.PLACEHOLDER")}
                                    disabled={isUpdate && !editData.companyId}
                                />
                            </div>
                            <h2 style={{ display: isUpdate && !editData.companyId ? 'none' : 'block' }}>{t("CLIENT.SUB_TITLE")}</h2>
                            <div className="section2" style={{ display: isUpdate && !editData.companyId ? 'none' : 'flex' }}>
                                <Checkbox
                                    label={"Due Diligence"}
                                    onClick={() => setPurchasedDueDiligence(!purchasedDueDiligence)}
                                    checked={purchasedDueDiligence}
                                />
                            </div>
                            {
                                purchasedDueDiligence &&
                                <div className={purchasedDueDiligence.toString()}>
                                    <h3>{t("CLIENT.SECOND_SUB_TITLE")}</h3>
                                    <DropdownPurchased
                                        dropdownPlaceholder={t("CLIENT.PURCHASED_DD.PLACEHOLDER")}
                                        errors={errors?.quota}
                                        control={control}
                                        onChange={(e) =>  setValue('quota', e.target.value, true)}
                                    />
                                </div>
                            }
                        </div>
                        <div className="ButtonSection">
                            <Button
                                funcButton={() => navigate("/")}
                                buttonContent={t("CLIENT.BUTTON.CANCEL")}
                            />
                            <Button
                                disabledProp={!purchasedDueDiligence || !verifyFields ? true : false}
                                buttonContent={t("CLIENT.BUTTON.SAVE")}
                                buttonType={"submit"}
                            />
                        </div>
                    </form>
                </Content>
            </Wrapper>
        </>
    )
}

export default Client