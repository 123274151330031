import styled from "styled-components";

export const Container = styled.div`
	padding: 46px;
	flex: 1;
	
	.radioButton {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`

export const WrapperSearch = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	gap: 15px;

	.filterButton{
		box-shadow: 0px 2px 4px #00000029;
		padding: 5px;
		width: 40px;
		height: 40px;
		border-radius: 50%;

		color:var(---primary-kroll);

		&:hover{
			cursor: pointer;
		}
	}
`;

export const WrapperMenu = styled.div`
		display: flex;
		align-items: center;
		width: 100%;
		gap: 20px;
		margin: 60px 0px;

		.btnUpload {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;

		p {
			font-weight: normal;
		}
	}

		.tittleWrapper{
			display: flex;
			align-items: center;
			gap:5px;
		
			svg{
				font-size: 20px;
				color: #14487F;
			}

			h2{
				color: #14487F;
				font-weight: 800;
				font-size: 2rem;
			}
	}
`;
