import api from 'config/api';
import { logout } from 'services/authentication';
import { SET_USER, REMOVE_USER } from '../types';

export function setUser (payload) {
    localStorage.setItem("@KimProject:user", JSON.stringify(payload));
    api.defaults.headers.common["Authorization"] = `Bearer ${payload.token}`;

    return {
        type: SET_USER,
        payload
    }
}

export function removeUser () {
    logout()
    localStorage.removeItem("@KimProject:user");
    delete api.defaults.headers.common["Authorization"];

    return {
        type: REMOVE_USER
    }
}
