import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from "react-router-dom";

//Styles
import {
	Logo,
	Wrapper,
	MainContent,
	ButtonContainer,
	LeftContent,
	RightContent,
	ForgotPassword,
	ErrorInput
} from './login.styled.jsx';

//Assets
import imgLogo from '../../assets/imgLogo.png';

//Components
import Button from '../../components/button/Button';
import Input from 'components/input/Input';
import InputPassword from './../../components/input/input-password/Input-password';
import Checkbox from './../../components/checkbox/Checkbox';
import ContactMenu from './../../components/contact-menu/Contact-Menu';

import { login } from '../../services/authentication';
import { setUser } from 'store/actions/user.action';
import { i18n } from "../../translate/i18n";
import ModalResetPassword from 'components/modal/modal-reset-password/Modal-Reset-Password.jsx';
import { useTranslation } from 'react-i18next';
import RegionFlags from 'components/region-flags/Region-Flags.jsx';

function Login() {
	const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [remember, setRemember] = useState(false);
	const [error, setError] = useState(false);

	const [searchParams, setSearchParams] = useSearchParams();
	const [firstAccessData, setFirstAccessData] = useState({});

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const { user } = useSelector(state => state);

	const { t } = useTranslation();

	useEffect(() => {
		if (user.token) {
			navigate("/home");
		}
	}, [user, navigate]);

	useEffect(() => {
		const isResetPassword = searchParams.get("resetPassword") === "true";
		if (isResetPassword) {
			setShowResetPasswordModal(true);
			setFirstAccessData(
				{
					email: searchParams.get("user"),
					code: searchParams.get("code")
				}
			)
		}
	}, [])

	const handleSubmit = async (e) => {
		e.preventDefault();

		const credentials = {
			email, password
		}

		login(credentials).then(userData => {
			dispatch(setUser(userData));
		}).catch(e => setError(true));
	};

	const handleResetPassword = (e) => {
		e.preventDefault();
		setShowResetPasswordModal(true)
	}

	return (
		<>
			<ModalResetPassword
				isOpen={showResetPasswordModal}
				handleClose={() => { window.location.href="/"}}
				firstAccess={firstAccessData}
			/>
			<ContactMenu />


			<Wrapper>
				<Logo src={imgLogo} alt="Logo Kroll" />

				<MainContent>
					<LeftContent>
						<span>
							<h1>{t("LOGIN.TITLE")} <strong>{t("LOGIN.TITLE_STRONG")}</strong>! {t('LOGIN.SUB_TITTLE')}</h1>
						</span>
					</LeftContent>

					<RightContent onSubmit={handleSubmit}>

						<Input
							labelInput="E-MAIL"
							type='email'
							placeholder={t("LOGIN.EMAIL_PLACEHOLDER")}
							value={email}
							onChange={(event) => setEmail(event.target.value)}
							required={true}
						/>

						<InputPassword
							labelInput={t("LOGIN.PASSWORD_LABEL")}
							placeholder={t("LOGIN.PASSWORD_PLACEHOLDER")}
							value={password}
							onChange={(event) => setPassword(event.target.value)}
							required={true}
						/>

						<ButtonContainer>
							<Checkbox
								label={t("LOGIN.REMEMBER_ME")}
								checked={remember}
								onClick={() => setRemember(!remember)}
							/>
							<Button
								buttonContent={t("LOGIN.LOGIN_BUTTON")}
								disabledProp={email.length > 0 && password.length > 0 ? false : true}
							/>
						</ButtonContainer>
						<ForgotPassword onClick={handleResetPassword}>Forgot or lost your password?</ForgotPassword>
						{error && <ErrorInput>{t("LOGIN.ERROR_MESSAGE")} <br />
							{t("LOGIN.SUB_ERROR_MESSAGE")}</ErrorInput>}
					</RightContent>
				</MainContent>
			</Wrapper>
		</>
	);
}

export default Login;