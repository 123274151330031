import { BsDot } from 'react-icons/bs'
import styled from 'styled-components'

export const WrapperText = styled.div`
	display: flex;
	align-items: center;
	margin-left: 30px;
`;

export const DetailedTypeIcon = styled(BsDot)`
	color: var(---primary-kroll);
`