import React from 'react'

//Styles
import { Wrapper } from './radio.styled'

const Radio = ({ label, nameValue, valueState, onChangeFunction }) => {

	return (
		<Wrapper>
			<input type="radio" name={nameValue} value={valueState} onChange={onChangeFunction} />
			<label htmlFor={label}>{label}</label>
		</Wrapper>
	)
}

export default Radio