import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import styled from "styled-components";

export const Wrapper = styled(Card)`

	@media(max-width: 1400px) {
		width: 366px;
		height: 120px;
	}

	width: 366px;
	height: 200px;

	&.MuiPaper-elevation0{
		box-shadow: 0px 2px 4px #00000029;
	}
	padding: 5px;
	`;

export const CardContainer = styled(CardContent)`
	.headerContent{
		display: flex;
		align-items: center;

		svg{
			margin-left: 10px;
			color:#14487F;
		}

		p{
		color: var(---primary-kroll-dark-font);
		@media(max-width: 1400px) {
			font-size: 12px;
		}
			font-size: 1rem;
		}
	}

	.cardContent{
		@media(max-width: 1400px) {
			height: 10vh;		
		}
		height: 15vh;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		p{
			@media(max-width: 1400px) {
			font-size: 12px;
		}
			font-size: 1rem;
		}

		h2{
			@media(max-width: 1400px) {
				font-size: 1.9rem;
		}
			font-size: 4rem;
		}
	}
`;