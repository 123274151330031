const messages = {
	pt: {
		translations: {
			FORGOT_PASSWORD: {
				EMAIL: {
					TITLE: 'Esqueceu ou perdeu sua senha?',
					BODY: "Informe o seu endereço de e-mail, e enviaremos um código para que você redefina a sua senha.",
					INPUT_PLACEHOLDER: "Informe seu endereço de e-mail...",
					SUBMIT_BUTTON: "Enviar",
				},
				CODE: {
					TITLE: 'Verifique o seu e-mail',
					BODY: "Enviamos um e-mail contendo um código para que você redefina a sua senha.",
					INPUT_PLACEHOLDER: "Digite o código de verificação...",
					SUBMIT_BUTTON: "Próximo",
					NEW_CODE: "Você não recebeu nosso código? Enviar novamente.",
					NEW_CODE_SENDED: "Um novo código foi enviado. Um novo código poderá ser enviado novamente dentro de"
				},
				PASSWORD: {
					TITLE: 'Redefina a sua senha',
					BODY: 'Digite a sua nova senha abaixo:',
					PASSWORD_PLACEHOLDER: 'Informe a sua nova senha',
					CONFIRM_PASSWORD_PLACEHOLDER: 'Confirme sua nova senha',
					SUBMIT_BUTTON: "Próximo",
					TITLE_FIRST_ACCESS: "Bem vindo ao App Kim!",
				},
				RESETED: {
					TITLE: 'Senha redefinida',
					BODY: 'Sua senha foi atualizada com sucesso!',
					SUBMIT_BUTTON: 'Concluir',
				}
			},
			LOGIN: {
				TITLE: 'Bem vindo ao',
				TITLE_STRONG: "KIM App",
				SUB_TITTLE: "Por favor insira suas credenciais para começar.",

				EMAIL_PLACEHOLDER: "Digite seu e-mail... ",
				PASSWORD_LABEL: "SENHA",
				PASSWORD_PLACEHOLDER: "Digite sua senha...",
				REMEMBER_ME: "Lembrar minha sessão",
				LOGIN_BUTTON: "Entrar",
				FORGOT_PASSWORD: "Esqueceu sua senha?",

				ERROR_MESSAGE: "Seu e-mail ou senha estão incorretos. Por favor corrija suas",
				SUB_ERROR_MESSAGE: "credenciais e tente novamente.",

				CONTACT_US: 'Fale com a gente'

			},
			LOGOUT_MODAL: {
				TITLE: "Você está prestes a sair do aplicativo!",
				BODY: "Certifique-se de que não existem alterações que não foram salvas.",
				BODY_STRONG: "Você tem certeza de que deseja sair?",
				CANCEL_BUTTON: "Cancelar",
				CONFIRM_BUTTON: "Confirmar"
			},
			PRE_HOME: {
				WELCOME: 'Bem vindo',
				WELCOME_MESSAGE: 'Para começar, escolha entre as nossas duas ferramentas abaixo.',
			},
			SIDEBAR: {
				OFF_KIM: "Mídias Adversas",
				EDIT_CLIENT: "Editar Cliente",
				NEW_CLIENT: "Novo Cliente",
				EDIT_USER: "Editar Usuário",
				NEW_USER: "Novo Usuário",
				FAQ: "FAQ",
				COLLAPSE: "Colapsar",
				EXTRACT: "Extratos (D.D)",
				EXIT: "Sair",

				MODAL_OKFF_KIM_TITLE: "Você está prestes a acessar um link externo!",
				MODAL_OKFF_KIM_BODY: "Certifique-se de que não existem alterações que não foram salvas.",
				MODAL_OKFF_KIM_BODY_STRONG: "Você tem certeza de que deseja sair?",
			},
			HOME: {
				SEARCH_INPUT_PLACEHOLDER: "Procurar por um sujeito específico...",
				LIST_LABEL: "Listas",
				DOWNLOAD_DROPDOWN: "Baixar",
				COMPANY: "Jurídico (CNPJ)",
				INDIVIDUAL: "Físico (CPF)",
				UPLOAD_NEW_LIST: "Subir nova lista",
				EXECUTED: "Executado",
				ON_EXECUTION: "Em execução",
				EXECUTION_QUEUE: "Em espera",
				DOWNLOAD_ALERT: "baixado com sucesso",
				FILTERS_APPLIED: "Filtros aplicados com sucesso",
				UPLOAD_ALERT: "enviado com sucesso",
				FILE: "Arquivo",
				USER_NAME: "Solicitante",

				MODAL_FAQ: {
					TITLE: "Como usar esta ferramenta?",
					FIRST_SUBTITLE: "Início",
					FIRST_PARAGRAPH: "Caso este seja o seu primeiro acesso, a sua homepage não exibirá nenhum dado. Para exibir as informações e iniciar a análise dos dados, baixe um template e carregue-o no sistema. Na homepage, existem dois botões relacionados a esta funcionalidade. ",
					SECOND_SUBTITLE: "Como fazer download de um template de listas?",
					SECOND_PARAGRAPH: "Existe um botão de download específico na homepage. Clique nesse botão, e selecione um item da dropdown list. O download será iniciado automaticamente. Após baixar o material, preencha todos os campos necessários e carregue-os no sistema. ",
					THIRD_SUBTITLE: "Como fazer upload de uma lista?",
					THIRD_PARAGRAPH: `Para subir uma lista no sistema, certifique-se de ter baixado o template. Vá para a homepage e clique no botão “Upload de nova lista”. Você verá uma janela de pop-up. Selecione “Processar apenas uma vez” caso você deseje processar a lista apenas uma vez. Caso contrário, selecione “Recorrência”, e diversos parâmetros serão exibidos na sua tela. Essas opções determinarão quantas vezes, e por quanto tempo, esta lista será processada. `,
					FOURTH_PARAGRAPH: "Caso você precise de ajuda, consulte a seção de ",
				},

				LISTS: {
					EMPTY_LIST: "Essa tabela parece estar vazia. Por favor faça o upload de uma lista para mostrar os dados.",
					CLIENT_NAME: "Nome do Cliente",
					NAME_LIST: "Nome da Lista",
					CATEGORY: "Categoria",
					SUBJECTS: "Qtd de Sujeitos",
					REQUEST_DATE: "Data de Solicitação",
					FINISH_DATE: "Data de Finalização",
				},

				MODAL: {
					LABEL_FIRST_TEXT: `Se você optar por rodar uma lista em ciclo, selecione a opção "Recorrência", caso contrário selecione "Execução Única" para executar a lista uma só vez.`,
					RADIO_RUN_ONLY_ONCE: "Execução Única",
					RECURRENCE: "Recorrência",
					RECURRENCE_MONTH: "Mensal",
					RECURRENCE_QUARTERLY: "Trimestral",
					RECURRENCE_SEMI: "Semestral",
					RECURRENCE_ANN: "Anual",

					HOW_MANY_TIMES_RUN: "Quantas vezes essa lista será executada?",
					WILL_RUN_UNTIL: "Esta lista será executada até:",

					SECOND_lABEL_FIRST: "Por favor selecione a lista desejada ao clicar no botão de upload abaixo. Acesse o FAQ para saber mais sobre formatos suportados, regras de upload e mais. Selecione a lista e clique em Enviar para começar o processo.",
					SELECT_FILE: "Selecione um arquivo",
					SUBMIT_BUTTON: "Enviar",

				},

				FILTER_SIDEBAR: {
					SIDEBAR_TITLE: "Filtros",
					SUB_TITLE: "Bandeiras",
					BLACK_FLAG: "Preto",
					RED_FLAG: "Vermelho",
					GREEN_FLAG: "Verde",

					REQUEST_DATE: "Data de Solicitação",
					CONCLUSION_DATE: "Data de Conclusão",
					REQUESTED_BY: "Solicitado por",
					REQUESTED_BY_PLACEHOLDER: "Selecione um cliente",
					BUTTON_APPLY: "Aplicar",
				},

				COLUMN_TABLE: {
					RECORRENCE_OF: "de",
					PROCESSING: 'Processando...'
				}
			},
			LIST_DETAIL: {
				LABEL: "Detalhes da lista",
				CLIENT_NAME: "Nome do Cliente",
				SUBJECT_NAME: "Nome do Sujeito",
				USER_INFO: "CPF/CNPJ",
				LIST_NAME: "Nome da Lista",
				REQUEST_DATE: "Data da Solicitação",
				FINISH_DATE: "Data da Finalização",
				DOWNLOAD_PDF: "Download PDF",
				CARD_SEARCH_SUBJECTS: "Sujeitos Pesquisados",
				CARD_RED_FLAGS_SUBJECTS: "Sujeitos com Red Flags",
				CARD_QTD_RED_FLAGS: "Quantidade total de Red Flags",
				CARD_MOST_RED_FLAGS: "Categoria com mais Red Flags",
				LIST_EXPAND_TITLE_CATEGORY: "Categorias",
				LIST_EXPAND_TITLE_QTD_FLAGS: "Qtd Flags",
				LIST_DASHBOARD_TITLE_SUBJECT_NAME: "Nome do Sujeito",
				LIST_DASHBOARD_DOC: "CPF/CNPJ",
				LIST_DASHBOARD_TITLE_QTD_FLAGS: "Qtd Flags",
				LIMIT_SUBJECTS_TEXT: "Limite de 20 sujeitos selecionados atingido",
			},
			RED_FLAGS: {
				CATEGORY: {
					PEP: "PEP",
					NATIONALSANCTIONS: "Sanções Nacionais",
					INTERNATIONALSANCTIONS: "Sanções Internacionais",
					OFFSHORE: "Offshore",
					CRIMINALPROCESS: "Processos Criminais",
					OTHERSJUDICIALPROCESS: "Outros Processos Judiciais",
					POLITICALDONATIONS: "Doações Políticas",
					ADVERSESMIDIAS: "Mídias Adversas"
				},

				TYPE: {

				}
			},

			CLIENT: {
				PREVIOUS_PAGE: "Novo Cliente",
				EDIT_PREVIOUS_PAGE: "Editar Cliente",
				TITLE: "Informações básicas",
				SUB_TITLE: "Produtos adquiridos*",
				SECOND_SUB_TITLE: "Produtos adquiridos (Due Diligence*)",
				ALERT_MESSAGE: "Cliente criado com sucesso",
				INPUTS: {
					COMPANY: {
						LABEL: "Empresa*",
						PLACEHOLDER: "Digite o nome da empresa",
						VALIDATION: {
							REQUIRED: 'O campo da empresa é obrigatório!',
							MIN_LENGTH: 'O campo empresa deve conter no mínimo 2 caracteres.',
							MAX_LENGTH: 'O campo empresa deve ter menos de 45 caracteres',
						},
					},
					DOCUMENT: {
						LABEL: "CNPJ",
						PLACEHOLDER: "Digite o CNPJ",
					},
					EMAIL: {
						LABEL: "E-mail*",
						PLACEHOLDER: "Digite um e-mail",
						VALIDATION: {
							REQUIRED: 'O campo de e-mail é obrigatório!',
							MIN_LENGTH: 'O campo e-mail deve conter no mínimo 4 caracteres.',
							MAX_LENGTH: 'O campo e-mail deve ter menos de 60 caracteres',
						},
					},
					PHONE: {
						LABEL: "Fone*",
						PLACEHOLDER: "Digite um número de telefone",
						VALIDATION: {
							REQUIRED: 'O campo de telefone é obrigatório!',
							MATCHES: 'O número de telefone não é válido',
						},
					},
					COUNTRY: {
						LABEL: "País",
						PLACEHOLDER: "Selecione um país",
					},
					STATE: {
						LABEL: "Estado",
						PLACEHOLDER: "Selecione um estado",
					},
					CITY: {
						LABEL: "Cidade",
						PLACEHOLDER: "Selecione uma cidade",
					},
					DISTRICT: {
						LABEL: "Bairro",
						PLACEHOLDER: "Digite o nome do bairro",
					},
					STREET: {
						LABEL: "Rua",
						PLACEHOLDER: "Digite o nome da rua",
					},
					NUMBER: {
						LABEL: "Número",
						PLACEHOLDER: "Digite o número",
					},
				},
				BUTTON: {
					CANCEL: "Cancelar",
					SAVE: "Salvar",
				},
				MODAL: {
					TITLE: "Criar um novo usuário",
					PARAGRAPH: "Você gostaria de criar um novo usuário atrelado a este cliente?",
					BUTTON: "Criar novo usuário",
				},
				ALERT: {
					TITLE: "Cliente criado com sucesso",
					EDIT_TITLE: "Cliente editado com sucesso"
				},
				PURCHASED_DD: {
					PLACEHOLDER: "Selecione o número de execuções",
					FIRST_OPTION: "Até 50 execuções",
					SECOND_OPTION: "Entre 51 e 150 execuções",
					THIRD_OPTION: "Entre 151 e 500 execuções",
					FOURTH_OPTION: "Mais de 500 execuções",
				},
			},

			USER: {
				EDIT_PREVIOUS_PAGE: "Editar Usuário",
				PREVIOUS_PAGE: "Novo Usuário",
				TITLE: "Informações básicas",
				INPUTS: {
					NAME: {
						LABEL: "Nome*",
						PLACEHOLDER: "Digite o nome da pessoa",
						VALIDATION: {
							REQUIRED: 'O campo de nome é obrigatório!',
							MIN_LENGTH: 'O nome deve conter no mínimo 2 caracteres.',
							MAX_LENGTH: 'O nome deve ser inferior a 30 caracteres',
						},
					},
					SURNAME: {
						LABEL: "Sobrenome*",
						PLACEHOLDER: "Digite o sobrenome da pessoa",
						VALIDATION: {
							REQUIRED: 'O campo de Sobrenome é obrigatório!',
							MIN_LENGTH: 'O sobrenome deve conter no mínimo 2 caracteres.',
							MAX_LENGTH: 'O sobrenome deve ser inferior a 30 caracteres',
						},
					},
					EMAIL: {
						LABEL: "E-mail*",
						PLACEHOLDER: "Digite um e-mail válido",
						VALIDATION: {
							REQUIRED: 'O campo de E-mail é obrigatório!',
							MIN_LENGTH: 'O e-mail deve conter no mínimo 4 caracteres.',
							MAX_LENGTH: 'O e-mail deve ter menos de 60 caracteres',
						},
					},
					PHONE: {
						LABEL: "Fone*",
						PLACEHOLDER: "Digite um número de telefone",
						VALIDATION: {
							REQUIRED: 'O campo de telefone é obrigatório!',
							MATCHES: 'O número de telefone não é válido',
						},
					},
					COMPANY: {
						LABEL: "Empresa*",
						PLACEHOLDER: "Selecione a empresa",
						VALIDATION: {
							REQUIRED: 'O campo da empresa é obrigatório!',
						},
					},
					USER: {
						LABEL: "Usuário*",
						PLACEHOLDER: "Selecione o usuário",
					},
				},
				BUTTON: {
					CANCEL: "Cancelar",
					SAVE: "Salvar",
				},
				MODAL: {
					TITLE: "Usuário criado com sucesso!",
					EDIT_TITLE: "Usuário editado com sucesso!",
					FIRST_PARAGRAPH: "Você gostaria de criar um novo usuário?",
					FIRST_BUTTON: "Sim, criar novo usuário",
					SECOND_BUTTON: "Não, criar somente um usuário",
					EDIT_FIRST_PARAGRAPH: "Você gostaria de editar um outro usuário?",
					EDIT_FIRST_BUTTON: "Sim, editar outro usuário",
					EDIT_SECOND_BUTTON: "Não, editar somente um usuário",
				},
			},
			STATEMENT: {
				TITLE_PAGE: "Declaração",
				FILTER_BY: "Filtrar por período",
				COMPANY: "Filtrar por cliente",
				COMPANY_PLACEHOLDER: "Selecione um cliente",
				BUTTON_CSV: "Exportação como CSV",
				LABEL_EXECUTIONS: "Execuções de",
				PERIOD: "Período",
				LIST: "Lista",
				REQUEST_BY: "Solicitado por",
				EXECUTIONS: "Execuções",
				LIST_EMPTY: "Esta lista parece estar vazia. Por favor, selecione um mês da lista suspensa para exibir informação.",
				EXECUTION: {
					EXECUTIONS: " Execuções ",
					FROM: "a partir de ",
					LIST: " Listas ",
				},
				MONTH_DAY_START: "De ",
				MONTH_DAY_END: " até ",
				YEAR_TITLE_LABEL: " de "
			}
		}
	}
};

export { messages }