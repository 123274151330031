import * as React from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from 'react-icons/ai';
import { WrapperTrable, TableCellWrapper, TableCellSmaller } from './list-expand-container.styled';
import CheckboxCell from 'components/checkbox-cell/CheckboxCell';
import { TableRow } from '@mui/material';
import ListExpandedItem from './list-expanded-item/List-expanded-Item';
import { i18n } from '../../../translate/i18n';
import { useTranslation } from 'react-i18next';

function Row(props) {
	const { t } = useTranslation();
	const { row, handleSelectItem, handleSelectCategory, key } = props;
	const [open, setOpen] = React.useState(false);

	return (
		<>
			<TableRow key={key}>
				<TableCell sx={{ width: "10px", border: "1px solid rgba(224, 224, 224, 1)" }}>
					<CheckboxCell
						checked={row.types.filter(item => item.selected).length === row.types.length}
						onChange={handleSelectCategory}
					/>
				</TableCell>
				<TableCell component="th" scope="row">
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ?
							<AiOutlineMinusSquare
								color={"#14487F"}
							/> :
							<AiOutlinePlusSquare
								color={"#14487F"}
							/>}
					</IconButton>
					{t(row.name)}
				</TableCell>
				<TableCellSmaller
					sx={{
						border: "1px solid rgba(224, 224, 224, 1)",
						textAlign: "center",
					}}
				>
					{row.qty}
				</TableCellSmaller>
			</TableRow>
			<TableRow key={key + '_detail'}>
				<TableCell
					style={{ padding: 0, border: '1px solid rgba(224, 224, 224, 1)' }}
					colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Table size="small" aria-label="purchases">
							<TableBody>
								{row.types.map((historyRow, index) => (
									<TableRow key={index} sx={{ '&:first-child td': { borderTop: 0 }, '&:last-child td': { borderBottom: 0 } }}>
										<TableCellWrapper sx={{ width: "57px", padding: "16px", borderLeft: 'none' }}>
											<CheckboxCell
												checked={historyRow.selected}
												onChange={() => handleSelectItem(historyRow.id)}
											/>
										</TableCellWrapper>
										<TableCellWrapper>
											<ListExpandedItem textItem={t(historyRow.name)} />
										</TableCellWrapper>
										<TableCellSmaller
											sx={{ textAlign: "center" }}
											scope="row">
											{historyRow.qty}
										</TableCellSmaller>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
}

export default function ListExpandContainer({
	rows,
	handleSelectItem,
	handleSelectCategory,
	handleSelectAll
}) {

	const { t } = useTranslation();

	return (
		<WrapperTrable
			component={Paper}
			elevation={1}
			sx={{
				borderRadius: '20px',
				borderShadow: 'none',
				border: "1px solid rgba(224, 224, 224, 1)",
				boxShadow: "0px 2px 4px #00000029"
			}}
		>
			<Table aria-label="collapsible table">
				<TableHead>
					<TableRow key={'header'}>
						<TableCell sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
							<CheckboxCell
								checked={rows.filter(item => item.types.filter(type => type.selected).length === item.types.length).length === rows.length}
								onChange={() => handleSelectAll()} />
						</TableCell>

						<TableCell
							sx={{ border: "1px solid rgba(224, 224, 224, 1)", fontWeight: "bold" }}>
							{t("LIST_DETAIL.LIST_EXPAND_TITLE_CATEGORY")}
						</TableCell>
						<TableCellSmaller
							sx={{
								fontWeight: "bold",
								textAlign: "center",
								border: "1px solid rgba(224, 224, 224, 1)"
							}}
						>
							{t("LIST_DETAIL.LIST_EXPAND_TITLE_QTD_FLAGS")}
						</TableCellSmaller>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row, index) => (
						<Row
							key={row.termId}
							row={row}
							handleSelectCategory={() => handleSelectCategory(index)}
							handleSelectItem={handleSelectItem} />
					))}
				</TableBody>
			</Table>
		</WrapperTrable>
	);
}