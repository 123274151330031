export const filterTable = (table, text, types) => {
	const regexEspecialCharacters = /[^a-zA-Z0-9]/g;
	let filteredTable = text.length > 0 ?
		table.filter(item => (
			item.name.toLowerCase().includes(text.toLowerCase())) ||
			item.document.number.toLowerCase()
				.includes(text.toLowerCase().replace(regexEspecialCharacters, ''))
		)
		: table;

	if (types.length > 0) {
		filteredTable = filteredTable.filter(item => {
			return item.flags.map(flag => flag.type).some(type => types.includes(type))
		})
	}


	return filteredTable;
}