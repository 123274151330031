import { SHOW_ALERT, HIDE_ALERT } from '../types';

const initialState = {
  open: false,
  type: '',
  text: ''
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ALERT:
      return {
        ...action.payload,
        open: true
      }
    case HIDE_ALERT:
      return {
        open: false,
        type: '',
        text: ''
      }
    default:
      return state;
  }
}

export default reducer;