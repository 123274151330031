import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeUser } from 'store/actions';
import RegionFlags from '../region-flags/Region-Flags'

// Styles
import { Wrapper, SidebarHeader, SidebarBottom, ProfilePhotoThumb, KrollIcon, ProfileInfo } from './sidebar.styled';

//Icons
import { FaRegUserCircle } from 'react-icons/fa';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { RiLogoutBoxLine } from 'react-icons/ri';
import { MdReceipt } from 'react-icons/md'
import { HiOutlineFolderAdd, HiOutlineFolder } from 'react-icons/hi'
import { CgUserAdd, CgUser } from 'react-icons/cg'
import { TbLayoutSidebarLeftCollapse, TbLayoutSidebarLeftExpand } from 'react-icons/tb'
import { GoLinkExternal } from 'react-icons/go';

import { i18n } from "../../translate/i18n";
import DropdownProducts from 'components/dropdown/dropdown-products/dropdown-products';
import { formatProduct } from 'utils/formatProduct';
import ModalExit from 'components/modal/modal-exit/Modal-exit';
import ModalFaq from 'components/modal/modal-faq/modal-faq';
import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';
// import { logoutUser } from 'store/actions';


function Sidebar({ exitButton }) {
	const { t } = useTranslation();
	const [isCollapsed, setIsCollapsed] = useState(false);
	const [products, setProducts] = useState([]);
	const [showLogoutModal, setShowLogoutModal] = useState(false);
	const [showOffKimModal, setShowOffKimModal] = useState(false);

	const navigate = useNavigate();

	const sideBarMenu = [
		{
			icon: <GoLinkExternal size={'26px'} />,
			text: t("SIDEBAR.OFF_KIM"),
			onClick: () => setShowOffKimModal(true),
			adminOnly: false,

		},
		{
			icon: <HiOutlineFolder size={'30px'} />,
			text: t("SIDEBAR.EDIT_CLIENT"),
			onClick: () => navigate('/client/edit'),
			adminOnly: true,
		},
		{
			icon: <HiOutlineFolderAdd size={'30px'} />,
			text: t("SIDEBAR.NEW_CLIENT"),
			onClick: () => navigate('/client'),
			adminOnly: true,
		},
		{
			icon: <CgUser size={'30px'} />,
			text: t("SIDEBAR.EDIT_USER"),
			onClick: () => navigate('/user/edit'),
			adminOnly: true,
		},
		{
			icon: <CgUserAdd size={'30px'} style={{ marginLeft: '10px' }} />,
			text: t("SIDEBAR.NEW_USER"),
			onClick: () => navigate('/user', { forceRefresh: true }),
			adminOnly: true,
		},
		{
			icon: <MdReceipt size={'26px'} />,
			text: t("SIDEBAR.EXTRACT"),
			onClick: () => navigate('/statment'),
			adminOnly: false,
		},
		{
			// icon: <img src={isCollapsed ? ButtonExpand : IconCollapse} alt="button-expand" className="expand-button-sidebar" /> ,]
			icon: isCollapsed ? <TbLayoutSidebarLeftExpand size={'30px'} /> : <TbLayoutSidebarLeftCollapse size={'30px'} />,
			text: t("SIDEBAR.COLLAPSE"),
			onClick: () => setIsCollapsed(!isCollapsed),
			adminOnly: false,
		},
		{
			icon: <RiLogoutBoxLine color={'#BF0D3E'} size={'30px'} />,
			text: t("SIDEBAR.EXIT"),
			onClick: () => setShowLogoutModal(true),
			adminOnly: false,
		},
	];

	const { user } = useSelector(state => state);

	useEffect(() => {
		user.company.products.map(productName => {
			const productNameFormated = formatProduct(productName.name)
			return setProducts(prevState => [...prevState, productNameFormated])
		});



	}, [user.company.products])

	const dispatch = useDispatch();


	const logout = useCallback(() => {
		dispatch(removeUser());
		navigate('/');
	}, [dispatch, navigate]);

	// function to acess off-kim
	const handleOffKim = () => {
		setShowOffKimModal(false)
		window.open("https://forms.office.com/pages/responsepage.aspx?id=RVXvbwmV_kCvawM4UrNFt_3e-06ThUNGphVAA4M3z3dUMVhTSTU5V1hJTVhPVFNORklQQ0xBOE5STS4u", '_blank');
	}

	const checkRoles = (item) => {
		return !(item.adminOnly && user.role !== "admin")
	}

	return (
		<Wrapper style={!isCollapsed ? { minWidth: '192px' } : { minWidth: '88px' }}>

			{/* Modal Logout */}
			<ModalExit
				isOpen={showLogoutModal}
				handleClose={() => setShowLogoutModal(false)}
				onConfirm={logout}
				modalTitle={t("LOGOUT_MODAL.TITLE")}
				modalBody={t("LOGOUT_MODAL.BODY")}
				modalBodyStrong={t("LOGOUT_MODAL.BODY_STRONG")}
			/>

			{/* Modal OFF-KIM */}
			<ModalExit
				isOpen={showOffKimModal}
				handleClose={() => setShowOffKimModal(false)}
				onConfirm={handleOffKim}
				modalTitle={t("SIDEBAR.MODAL_OKFF_KIM_TITLE")}
				modalBody={t("LOGOUT_MODAL.BODY")}
				modalBodyStrong={t("LOGOUT_MODAL.BODY_STRONG")}
			/>
			<SidebarHeader>
				<ProfileInfo>
					<ProfilePhotoThumb>
						<KrollIcon src={process.env.PUBLIC_URL + '/kroll_icon.png'} />
					</ProfilePhotoThumb>
					{
						!isCollapsed &&
						<div className="sidebar-header-info"
							style={{ opacity: '1' }}>
							<p>{user.name} {user.surname.toUpperCase()[0]}.</p>
							<DropdownProducts values={products} />
						</div>
					}
				</ProfileInfo>
				<RegionFlags/>
			</SidebarHeader>

			<SidebarBottom>
				{
					sideBarMenu.filter(checkRoles).map((item, index) => (
						<span key={index} onClick={item.onClick}>

							{item.icon}
							{
								!isCollapsed && <p>{item.text}</p>
							}
						</span>
					))
				}
			</SidebarBottom>

		</Wrapper>
	);
}

export default Sidebar;