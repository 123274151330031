import styled from "styled-components";

export const Content = styled.div`

    padding: 46px;
	flex: 1;
    display: flex;
    flex-direction: column;
   
    .MuiList-root {
        max-height: 200px;
    }

    h1, h2{
        color:var(---primary-kroll);
        text-align: left;
        font: normal normal bold 16px/22px Nunito Sans;
        letter-spacing: 0px;
        color: #14487F;
        opacity: 1;
    }

    h1{
        margin-top: 100px;
    }

    h2{
        margin-top: 32px;
        margin-bottom: 24px;
    }

    form{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex: 1;
    }

    .mainContent {
        flex: 1;
    }

    .section{
        display: grid;
        grid-template-columns: repeat(5, 1fr);;
        grid-column-gap: 24px;
        gap: 24px;
        justify-content: space-between;
        margin-top: 24px;
        
        @media(max-width: 1800px){
            grid-template-columns: repeat(3, 2fr);
        }

        @media(max-width: 1198px){
            grid-template-columns: repeat(2, 2fr);
        }

        @media(max-width: 898px){
            grid-template-columns: repeat(1, 1fr);
        }
    }

    label{
        color: var(---primary-kroll-dark-font);
        font-weight: bold;
        margin-bottom: 6px;
    }

    .ButtonSection{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 24px;
    }

    .editUserOptions {
        display: flex;
        flex-direction: column;
    }
`