import api from 'config/api';
import axiosConfig from 'config/axiosConfig';

export const login = async params => {
	const response = await api.post(`${axiosConfig.LOGIN_API_URL}/login`, params, { hideAlert: true });

	return response.data;
};

export const logout = async params => {
	const response = await api.get(`${axiosConfig.LOGIN_API_URL}/logout`, params, { hideAlert: true });

	return response.data
}

export const resetPasswordEmail = async email => {
	const response = await api.post(`${axiosConfig.LOGIN_API_URL}/email/code`, {
		type: 'reset',
		email
	});

	return response.data;
};

export const resetPasswordCheckCode = async (email, code) => {
	const response = await api.get(`${axiosConfig.LOGIN_API_URL}/email/code-verify`, {
		params: {
			email,
			code
		}
	});

	return response.data;
};

export const resetPasswordNewPassword = async (email, code, password) => {
	const response = await api.put(`${axiosConfig.LOGIN_API_URL}/email/reset-password`, {
		email,
		code,
		password
	});

	return response.data;
};
