import api from 'config/api';
import axiosConfig from 'config/axiosConfig';

export const upload = async (data, params) => {
	const response = await api.post(`${axiosConfig.HOME_API_URL}/upload/excell`, data, { params });

	return response.data;
};

export const getRunningLists = async params => {
	const response = await api.get(`${axiosConfig.HOME_API_URL}/running`, params);

	return response.data.list;
};

export const getExecutedLists = async params => {
	const response = await api.get(`${axiosConfig.HOME_API_URL}/executed`, params);

	return response.data.list;
};

export const getOnHoldLists = async params => {
	const response = await api.get(`${axiosConfig.HOME_API_URL}/onhold`, params);

	return response.data.list;
};

export const getDetailedList = async listId => {
	const response = await api.get(`${axiosConfig.HOME_API_URL}/detail/${listId}`);

	return response.data.list[0];
};

export const getDashboardList = async listId => {
	const response = await api.get(`${axiosConfig.HOME_API_URL}/dashboard/${listId}`);
	return response.data;
};

export const generateReportToDownload = async (id, termsIds, type) => {
	const response = await api.get(`${axiosConfig.HOME_API_URL}/report`, {
		params: {
			listId: id,
			terms: [termsIds],
			type
		}
	});

	return response.data
}

export const downloadSelectedFromList = async (id, termsIds, type) => {
	const response = await api.get(`${axiosConfig.HOME_API_URL}/report/download`, {
		responseType: 'blob', params: {
			listId: id,
			terms: [termsIds],
			type
		}
	})
	const url = URL.createObjectURL(new Blob([response.data]))
	const link = document.createElement("a");
	link.download = 'kroll-reports.zip';
	link.href = url;
	link.click();
}