import * as React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Wrapper } from './search-input.styled';
import { InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';

export default function InputSearch({ placeholder, onChangeFunction, value }) {
	return (
		<Wrapper
			component="div"
			noValidate
			autoComplete="off"
			sx={{ overflow: "hidden" }}
		>
			<TextField
				placeholder={placeholder}
				onChange={onChangeFunction}
				value={value}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start" sx={{ height: "45px", width: "25px" }}>
							<SearchIcon />
						</InputAdornment>
					),
				}}
			/>
		</Wrapper>
	);
}