import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Controller } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import { Wrapper, WrapperSelect, Label, FormWrapper } from './label-dropdown.styled';
export function LabelDropdown(props) {

  const {
    data,
    dropdownPlaceholder,
    disabled,
    label,
    onBlur,
    errors,
    control,
    name,
    handleChange
  } = props;

  const MenuProps = {
    MenuListProps: {
      style: {
        maxHeight: '260px',
      }
    },
  };

  return (
    <Wrapper>
      <FormWrapper>
        <Label>{label}</Label>
        <Controller name={name} control={control} render={({ field : { onChange, value }}) => (
          <WrapperSelect
            input={<OutlinedInput/>}
            variant={"outlined"}
            name={name}
            MenuProps={MenuProps}
            defaultValue="defaultValue"
            disabled={disabled}
            onChange={e => { 
              onChange(e)
              handleChange && handleChange(e)
            }}
            value={value ?? "defaultValue"}
          >
            <MenuItem
              value="defaultValue"
              disabled>
              <p style={{
                color: "#4D4D4F",
                fontSize: "16px"
              }}>{dropdownPlaceholder}</p>
            </MenuItem>
            {data.map((item, index) => (
              <MenuItem
                value={item.name}
                key={item.isoCode || index}
              >
                {item.name}
              </MenuItem>
            ))}
          </WrapperSelect>
          )}/>
      </FormWrapper>
      {errors?.message && <span>{errors?.message || ''}</span>}
    </Wrapper>
  );
}