import styled from "styled-components";

export const Wrapper = styled.button`
		height: 56px;
		font-family: 'Nunito Sans', sans-serif;
		background-color: var(---primary-kroll);
		box-shadow: 0px 3px 6px #00000029;
		border-radius: 5px;
		border: none;

		color: var(---primary-font-color);
		font-size: var(---text-font-size);
		transition: ease-out 0.3s;
		font-weight:bold;
		letter-spacing: 0px;

		:hover{
			background-color: var(---secondary-kroll);
		}

		:disabled{
			background-color: #ffff;
			border: 1px solid var(---primary-kroll);
			color:	var(---primary-kroll);

			:hover{
				cursor: not-allowed;
			}
		}
`;