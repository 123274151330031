// AUTHENTICATION 
export const SET_USER = "SET_USER";
export const REMOVE_USER = "REMOVE_USER";

// HOME 
export const SET_IN_EXECUTION = "SET_IN_EXECUTION";

// REFRESH TOKEN
export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

// LOADER
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

// ALERT 
export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';
