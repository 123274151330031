import styled from "styled-components";
import Box from '@mui/material/Box';


export const ModalContent = styled(Box)`
	width: 735px;
	height: 400px;
	padding: 60px 70px;
	gap: 25px;
	outline: none;
	background: #fff;
	border: none;
	border-radius: 8px;
	box-shadow: 12px;
	display: flex;
	align-items:	center;
	justify-content: center;
	flex-direction: column;

	.modalContent{
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin-top: 35px;
		gap: 30px;

		h1{
			color: var(---primary-kroll);
			text-align: center;
		}

		p{
			text-align: center;
		}
	}

	.buttonWrapper{
		display: flex;
		gap:40px;
	}
`;

export const IconWrapper = styled.div`
	position: fixed;
	border: 1px	solid black;
	width: 134px;
	box-shadow: 0px 3px 6px #00000029;
	border: 3px solid #E2665C;
	background: #E2665C;
	padding: 25px;
	border-radius: 100%;

	display:	flex;
	align-items:	center;
	justify-content:	center;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10000;



`

export const Wrapper = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	flex-direction: column;

	svg{
		color: #fff;
		position: fixed;
		width: 120px;
		height: 120px;
		border: 1px	solid black;
		box-shadow: 0px 3px 6px #00000029;
		border: 3px solid #E2665C;
		background: #E2665C;
		padding: 25px;
		border-radius: 100%;
		z-index: 10000;
		margin-top: -200px;
	}
`