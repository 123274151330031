import i18n from 'i18next';
import { messages } from './languages';
import { initReactI18next } from 'react-i18next';

const selectedDefaultLanguage = localStorage.getItem('@KimProject:language')

i18n.use(initReactI18next).init({
	debug: false,
	defaultNS: ['translations'],
	lng: selectedDefaultLanguage || 'en-US',
	fallbackLng: 'en-US',
	ns: ['translations'],
	resources: messages,
});

export default i18n;