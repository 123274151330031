import { Modal } from "@mui/material";
import Button from "components/button/Button"
import { t } from "i18next";
import React from "react";
import { BsExclamationTriangle } from 'react-icons/bs';
import { useNavigate } from "react-router-dom";
import { ContentWrapper, Circle, Contents, Paragraph, Title, ModalWrapper, ButtonExit, } from "./modal-user.styled";

export const ModalUser = ({ isOpen, handleClose, isUpdate }) => {
    const navigate = useNavigate();
    return (
        <>
            <Modal
                open={isOpen}
                onClose={handleClose}
            >
                <ModalWrapper>
                    <ContentWrapper>
                        <Contents>
                            <Circle>
                                <BsExclamationTriangle />
                            </Circle>
                            <Title>{t("USER.MODAL.EDIT_TITLE")}</Title>
                            <Paragraph>
                                    {isUpdate ? t("USER.MODAL.EDIT_FIRST_PARAGRAPH") : t("USER.MODAL.FIRST_PARAGRAPH")}
                            </Paragraph> 
                            <div className="ButtonSection">
                                <Button 
                                    funcButton={() => window.location.reload()}
                                    buttonContent={t("USER.MODAL.EDIT_FIRST_BUTTON")}
                                    widthProp={"270px"}
                                /> 
                                <Button 
                                    funcButton={() => navigate("/")}
                                    buttonContent={t("USER.MODAL.EDIT_SECOND_BUTTON")}
                                    widthProp={"270px"}
                                />
                            </div>
                        </Contents>
                    </ContentWrapper>
                    <ButtonExit onClick={handleClose}>X</ButtonExit>
                </ModalWrapper>
            </Modal>
        </>
    )
}

export default ModalUser