import { Box } from "@mui/system";
import styled from "styled-components";

export const Logo = styled.div`
    display: flex;
    justify-content: center;
`

export const Circle = styled.div`
    width: 134px;
    height: 134px;
    border-radius: 50%;
    display: inline-block;
    background: var(---secondary-punch) 0% 0% no-repeat padding-box;
    border: 3px solid var(---secondary-punch);
    background: #E2665C 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 3px solid #E2665C;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 70px;
    position: fixed;
	z-index: 10000;
    transform: translate(112.2%, -102.5%);
`

export const Title = styled.h1`
    color: var(---primary-kroll-blue);
    font: normal normal bold 24px/32px Nunito Sans;
    color: #14487F;
    opacity: 1;
    display: flex;
    justify-content: center;
    margin-top: 16px;
` 
    
export const Paragraph = styled.p`
    color: var(---primary-kroll-shadow);
    text-align: left;
    font: normal normal normal 16px/22px Nunito Sans;
    letter-spacing: 0px;
    color: #4D4D4F;
    margin-top: 18px;   
    margin-bottom: 18px;
    text-align: center;
`

export const Contents = styled.div`
    display: flex;
    flex-direction: column;
    aling-itens: center;
`

export const ModalWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999
`

export const ContentWrapper = styled(Box)`
    width: 608px;
    height: 305px;;
    background: var(---primary-white) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
	background: #fff;
	border: none;
	border-radius: 8px;
	box-shadow: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    outline: none;
    position: relative;

    .ButtonSection{
        display: flex;
        gap: 24px;
    }
`
export const ButtonExit = styled.button`
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background: var(---primary-white) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    color: #14487F;
    font: normal normal normal 35px Nunito Sans;
    opacity: 1;
    opacity: 1;
    position: fixed;
    transform: translate(0%, 325%);
    
    :hover{
        color: white;
        background-color: var(---secondary-kroll);
    }
`

