import React, { useEffect, useState } from 'react'

import PreviousPage from 'components/previous-page/Previous-Page'
import Button from './../../components/button/Button';
import Wrapper from 'components/wrapper/Wrapper';
import { Container } from './list-detail.styled';
import { downloadSelectedFromList, getDetailedList, generateReportToDownload } from 'services/home';
import { i18n } from 'translate/i18n';
import Card from 'components/card/Card';
import InputSearch from 'components/input/input-search/Search-Input';

// Icons
import { AiFillFlag } from 'react-icons/ai';
import { RiErrorWarningLine } from 'react-icons/ri';
import { BsSearch } from 'react-icons/bs';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';


import { formatDate } from 'utils/date';
import ListExpandContainer from 'components/list-container/list-expand-container/List-expand-container';
import ListDashboard from './../../components/list-container/list-dashboard/List-dashboard';
import { processCategories, processData } from './../../services/dd-dashboard';
import { filterTable } from 'utils/filter-table';
import Alert from '../../components/alert/Alert'
import { useTranslation } from 'react-i18next';

function ListDetail() {
	const [isSelectedItem, setIsSelectedItem] = useState(false);
	const [selectedItens, setSelectedItens] = useState([]);
	const [listData, setListData] = useState({});
	const [listDetails, setListDetails] = useState([]);
	const [listExpanded, setListExpanded] = useState([]);
	const [textFilter, setTextFilter] = useState('');
	const [summaryData, setSummaryData] = useState({});
	const [termsData, setTermsData] = useState([]);
	const [openAlert, setOpenAlert] = useState(false);

	const detailedList = window.history.state?.usr?.detailedList;

	useEffect(() => {
		const loadData = async () => {
			const listDetailsData = await getDetailedList(detailedList.id);
			const processedListData = processData(listDetailsData);
			const { summary, flags, terms } = processedListData;
			setListData(listDetailsData)
			setSummaryData(summary);
			setListExpanded(flags);
			setListDetails(terms);
			setTermsData(terms);
		}

		loadData()
	}, [detailedList.id]);

	useEffect(() => {
		const selectedTypes = listExpanded.map((category) => category.types.filter(type => type.selected).map(type => type.type)).flat()
		const filteredTerms = filterTable(termsData, textFilter, selectedTypes);
		setListDetails(filteredTerms);
	}, [textFilter, termsData, listExpanded])

	useEffect(() => {
		if (listData?.terms) {
			const { flags } = processCategories(listData, textFilter)
			setListExpanded(flags)
		}
	}, [textFilter, listData])

	const { t } = useTranslation();

	//Primeiro checkbox da tabela
	const handleSelectAllExpandedTable = () => {
		setListExpanded(
			(prevTest) => {
				const selectedCategories = prevTest.filter(item => item.types.filter(type => type.selected).length === item.types.length)
				return prevTest.map((test) => {
					if (selectedCategories.length === prevTest.length) {
						return {
							...test,
							types: test.types.map(item => (
								{
									...item,
									selected: false
								}
							))
						}
					} else {
						return {
							...test,
							types: test.types.map(item => (
								{
									...item,
									selected: true
								}
							))
						}
					}
				})
			}
		)
	}

	//seleciona o pai dos sub-itens checkbox da tabela
	const handleSelectFlag = (key) => {
		setListExpanded(
			(prevTest) => prevTest.map(
				(test, index) => {
					const selectedTypes = test.types.filter((item) => item.selected)
					if (selectedTypes.length === test.types.length) {
						return index === key ?
							{
								...test,
								types: test.types.map(item => (
									{
										...item,
										selected: false
									}
								))
							} :
							test
					} else {
						return index === key ?
							{
								...test,
								types: test.types.map(item => (
									{
										...item,
										selected: true
									}
								))
							} :
							test
					}
				}
			)
		);
	}

	const handleSelectFlagType = (key) => {
		setListExpanded(
			(prevTest) => prevTest.map(item => {
				if (item.types.filter(current => current.selected).length === item.types.length) {
					return {
						...item,
						selectedAll: false,
						types: item.types.map((type) => (
							key === type.id ? { ...type, selected: !type.selected } : type
						))
					}
				} else {
					return {
						...item,
						types: item.types.map((type) => (
							key === type.id ? { ...type, selected: !type.selected } : type
						))
					}
				}
			}
			)
		);
	}

	const handleSelectAllFlags = (select) => {
		setListExpanded(
			(prevTest) => prevTest.map(
				test => {
					return { ...test, selected: select }
				}
			)
		);
	}

	const handleSelectItem = (key, selected) => {
		if ((listDetails.filter((item => item.selected)).length < 20 || selected)) {
			setListDetails(
				(prevTest) => prevTest.map(
					(test, index) => {
						const testKey = test.termId || index;
						return testKey === key ?
							{ ...test, selected: !test.selected } :
							test
					}
				)
			);
		} else {
			setOpenAlert(true)
			setTimeout(() => {
				setOpenAlert(false)
			}, "2000")
		}
	};

	const handleSelectAllItemsTerms = (select) => {
		setListDetails(
			(prevTest) => prevTest.map(
				test => {
					return { ...test, selected: select, }
				}
			)
		);
	};

	useEffect(() => {
		const findedValue = listDetails.find(item => item.selected === true);
		if (findedValue) {
			setIsSelectedItem(true);
			const selectedValues = listDetails.filter(item => item.selected === true).map(e => e.termId);
			setSelectedItens(selectedValues)
		} else {
			setIsSelectedItem(false);
		}
	}, [listDetails, isSelectedItem])

	const handleDownloadPdf = async () => {
		await generateReportToDownload(detailedList.id, selectedItens, 'pdf');
		await downloadSelectedFromList(detailedList.id, selectedItens, 'pdf');
	};

	const handleDownloadDocx = async () => {
		await generateReportToDownload(detailedList.id, selectedItens, 'docx');
		await downloadSelectedFromList(detailedList.id, selectedItens, 'docx');
	};

	return (
		<Wrapper>
			<Container>
				<PreviousPage label={detailedList.listName + ` (${formatDate(detailedList.dateRequest)})`} destiny={"/home"} />
				<Alert open={openAlert} setOpen={setOpenAlert} type="error" text={t("LIST_DETAIL.LIMIT_SUBJECTS_TEXT")} />
				<div className="cardsContainer">
					<Card
						cardTitle={t("LIST_DETAIL.CARD_SEARCH_SUBJECTS")}
						icon={<BsSearch size={20} />}
						content={summaryData?.totalTerms}
						isRedFlag={false}
					/>
					<Card
						cardTitle={t("LIST_DETAIL.CARD_RED_FLAGS_SUBJECTS")}
						icon={<ReportProblemOutlinedIcon size={20} style={{ color: "#14487F" }} />}
						content={summaryData?.redFlagTerms}
						isRedFlag={true}
					/>
					<Card
						cardTitle={t("LIST_DETAIL.CARD_QTD_RED_FLAGS")}
						icon={<AiFillFlag size={20} />}
						content={summaryData?.totalRedFlags}
						isRedFlag={true}
					/>
					<Card
						cardTitle={t("LIST_DETAIL.CARD_MOST_RED_FLAGS")}
						icon={<RiErrorWarningLine size={20} />}
						type={t(summaryData?.mostRedFlagsCategory?.name || '')}
						content={summaryData?.mostRedFlagsCategory?.percentage && (summaryData?.mostRedFlagsCategory?.percentage + "%")}
						isRedFlag={true}
					/>
				</div>

				<div className='mainContent'>
					<InputSearch
						placeholder={t("HOME.SEARCH_INPUT_PLACEHOLDER")}
						onChangeFunction={(e) => setTextFilter(e.target.value)}
						value={textFilter}
					/>
					<div className='listWrapper' >
						<ListExpandContainer
							rows={listExpanded || []}
							handleSelectAll={() => handleSelectAllExpandedTable()}
							handleSelectCategory={(event) => handleSelectFlag(event)}
							handleSelectItem={(event) => handleSelectFlagType(event)}
							handleOnChangeCheckbox={(event) => handleSelectAllFlags(event)}
						/>
						<ListDashboard
							rows={listDetails || []}
							handleSelectItem={handleSelectItem}
							handleOnChangeCheckbox={(event) => handleSelectAllItemsTerms(event)}
						/>
					</div>
				</div>


				<div className='buttonsContainer'>
					<Button
						buttonContent={"Download DOCX"}
						disabledProp={isSelectedItem ? false : true}
						funcButton={handleDownloadDocx}
					/>
					<Button
						buttonContent={t("LIST_DETAIL.DOWNLOAD_PDF")}
						disabledProp={isSelectedItem ? false : true}
						funcButton={handleDownloadPdf}
					/>
				</div>

			</Container>
		</Wrapper>
	)
}

export default ListDetail
