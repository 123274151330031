import styled from "styled-components";
import TableContainer from '@mui/material/TableContainer';
import { TableCell } from "@mui/material";

export const WrapperTrable = styled(TableContainer)`
    &.MuiPaper-root{
        max-width:  1028px;
        border-radius: 20px;
        flex: 1;
        border: 1px solid rgba(224, 224, 224, 1);
    }
    .MuiTableCell-root{
        border-bottom: none;
    }
`;


export const WrapperCell = styled(TableCell)`

    &.MuiTableCell-root{
        width: 56px;
        border: none;
    }

    .cellItem{
        display: flex;
        align-items: center;
        gap: 5px;
        padding-left:15px;
        width: 125px;

        p,svg{
            color: #4D4D4F
        }
        
    }
`;

export const EmptyList = styled.div`
    width: 859px;
    height: 600px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media(max-width: 1400px){
        height: 250px;
    }

	p{
		color: var(---primary-kroll-dark-font);
	}

`