import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import { Controller } from 'react-hook-form';
import { FormControl } from '@mui/material';
import { Dropdown, Label } from './dropdown-purchased-dd.styled';
import { t } from 'i18next';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    }
  },
};

export default function DropdownPurchased({ dropdownPlaceholder, label, control }) {

  const data = [
    {
      value: "50",
      text: t("CLIENT.PURCHASED_DD.FIRST_OPTION"),
    },
    {
      value: "51 e 150",
      text: t("CLIENT.PURCHASED_DD.SECOND_OPTION"),
    },
    {
      value: "151 e 500",
      text: t("CLIENT.PURCHASED_DD.THIRD_OPTION"),
    },
    {
      value: "+500",
      text: t("CLIENT.PURCHASED_DD.FOURTH_OPTION"),
    },

  ]

  return (
    <>
      <FormControl>
        <Label>{label}</Label>
        <Controller
        name="quota"
        control={control}
        render={({ field: { onChange, value }}) => (
          <Dropdown
          input={<OutlinedInput/>}
          name={'quota'}
          onChange={onChange}
          MenuProps={MenuProps}
          defaultValue='placeholderValue'
          value={value ?? 'placeholderValue'}
        >
          <MenuItem
            value="placeholderValue"
            disabled>
            <p style={{
              color: "#4D4D4F",
              fontSize: "14px"
            }}>{dropdownPlaceholder}</p>
          </MenuItem>
          {
            data.map(e => (
              <MenuItem
                value={e.value}
                key={e.value}
              >
                {e.text}
              </MenuItem>
            ))
          }
        </Dropdown>
        )} />
      </FormControl>
    </>
  );
}