import styled from "styled-components";
import Box from '@mui/material/Box';

export const BoxWrapper = styled(Box)`

	width: 608px;
	height: 922px;
	padding: 20px 80px;
	gap: 25px;
	top: 50%;
	left: 50%;

	position: absolute;

	transform: translate(-50%, -50%);
	background: #fff;
	border: none;
	border-radius: 8px;
	box-shadow: 12px;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;	

	p{
		text-align: justify;
		font-size: 1rem;
	}

	@media(max-width: 1400px) {
		width: 608px;
		height: 680px;
		padding: 0px 80px;
		gap: 24px;

		top: 50%;
		left: 50%;

		p{
			margin-block-start: 0em;
	}
	}

	input[type="file"] {
		display: none;
	}
	.labelFileUpload{
		padding-left:25px;
		box-shadow: 0px 2px 4px #00000029;
		width: 438px;
		height: 56px;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
			span{
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
			}

		.iconButton{
			background-color: var(---secondary-kroll);
			height: 100%;
			width: 86px;
			color: #fff;
			border-radius: 0px 5px 5px 0px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
`

export const ImageItem = styled.img`

	@media(max-width: 1400px) {
		display: none;
	}

	margin: 0 auto;
	width: 172px;
	height: 259px;
`

export const RadioWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	padding: 0px 0px	0px 6px;
	width: 100%;
	gap: 10px;

	.radioInput{
		width: 100%;
		gap:	15px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.timesWilRun{
		display: flex;
		align-items: center;
		gap: 10px;

		input{
			width: 37px;
			height: 10px;
			box-shadow: 0px 2px 4px #00000029;
			border-radius: 5px;
		}

	}
	.listWillRun p{
		font-size: 13px;
			color:var(---primary-kroll-green);
	}
`;