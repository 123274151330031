import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import { Wrapper, WrapperSelect, Label, FormWrapper } from './dropdown.styled';

const MenuProps = {
    MenuListProps: {
        style: {
            maxHeight: '260px',
        }
    },
};


export default function Dropdown({
    data,
    onChange,
    name,
    label,
    dropdownPlaceholder }) {

    return (
        <Wrapper>
            <FormWrapper>
                <Label>{label}</Label>
                <WrapperSelect
                    input={<OutlinedInput />}
                    variant={"outlined"}
                    name={name}
                    onChange={onChange}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                    defaultValue="defaultValue"
                >
                    <MenuItem
                        value="defaultValue"
                        disabled
                    >
                        <p style={{
                            color: "#4D4D4F",
                            fontSize: "16px"
                        }}>{dropdownPlaceholder}</p>
                    </MenuItem>
                    {data.map((item, index) => (
                        <MenuItem
                            value={item.id}
                            key={index}
                        >
                            {item.name}
                        </MenuItem>
                    ))}
                </WrapperSelect>
            </FormWrapper>
        </Wrapper>
    );
}