import { SHOW_ALERT, HIDE_ALERT } from '../types';

export function showAlert(payload) {
  return {
    type: SHOW_ALERT,
    payload
  }
}

export function hideAlert() {
  return {
    type: HIDE_ALERT,
  }
}