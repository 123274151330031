import { SHOW_LOADER, HIDE_LOADER } from '../types';

const initialState = {
  visible: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return {
        visible: true
      }

    case HIDE_LOADER:
      return {
        visible: false
      }
    default:
      return state;
  }
}

export default reducer;