import styled from "styled-components";

export const Wrapper = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Input = styled.input.attrs({ type: 'checkbox' })`
	cursor: pointer;
	margin-top: 10px;
	margin-right: 8px;
	-moz-appearance:none;
	-webkit-appearance:none;
	-o-appearance:none;
	appearance: none;
	transform: translateY(-0.075em);
	width: 24px;
  	height: 24px;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 5px;
	display: grid;
  place-content: center;
	&::before {
		content: "";
		width: 16px;
		height: 16px;
		transform: scale(0);
		transition: 120ms transform ease-in-out;
		box-shadow: inset 16px 16px var(---primary-kroll);
		border-radius: 0.1rem;
	}
	&:checked::before {
		transform: scale(1);
	}

`;

export const Label = styled.p`
	cursor: pointer;
	color: var(---primary-kroll-dark-font);
	margin-top: 10px;
`;