import React from 'react'

import {
	Wrapper,
	BlackFlag,
	DetailsIcon,
	GreenFlag,
	RedFlag,
} from './column-table.styled';

import { i18n } from "../../../translate/i18n";
import { useNavigate } from 'react-router-dom';
import { formatDate } from 'utils/date';
import { formatCnpjCpf } from 'utils/document';
import { useTranslation } from 'react-i18next';


function ColumnTable({
	type,
	individualCondition,
	recorrence,
	recorrenceValue,
	value,
	name
}) {

	const arrFlags = { "black": <BlackFlag />, "green": <GreenFlag />, "red": <RedFlag /> }

	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleListDeatil = (value) => {
		navigate('/list-detail', { state: { detailedList: value } });
	}

	return (
		<Wrapper>
			{
				type === "executed" && <div className="wrapperExecuted">
					<div className="flagContainer">
						<span>
							{arrFlags[value[name]]}
						</span>
					</div>
					<DetailsIcon onClick={() => handleListDeatil(value)} fontSize={"small"} />
				</div>
			}

			{
				type === "in execution" && <div>
					{t('HOME.COLUMN_TABLE.PROCESSING')}
				</div>
			}

			{
				type === "on hold" && <div>
					{value[name] ? value[name].ocurrence.split('/', 1) : ''} {t("HOME.COLUMN_TABLE.RECORRENCE_OF")} {value[name] ? value[name].ocurrence.split('/').pop() : ''}
				</div>
			}

			{
				type === "individual" && <div>
					{
						individualCondition && <GreenFlag />
					}
					{
						(!individualCondition && individualCondition !== null) && <RedFlag />
					}
					{
						individualCondition === null && <BlackFlag />
					}
				</div>
			}

			{
				type === "date" && (value[name] ? formatDate(value[name]) : '')
			}

			{
				type === 'document' && (value[name] ? formatCnpjCpf(value[name]) : '')
			}
		</Wrapper>
	)
}

export default ColumnTable