import { FormControl, Select } from "@mui/material";
import styled from "styled-components";

export const Wrapper = styled.div`
        span{
			color: var(---danger-font-color)
		}
`

export const Label = styled.label`
    color: var(---primary-kroll-dark-font);
    font-weight: bold;
    margin-bottom: 6px;
`

export const WrapperSelect = styled(Select)`
    width: 288px;
    height: 56px;
    background-color: var(---primary-font-color);

    :hover{
        fieldset{
            border: 1px solid #E5E5E5;
        }
    }

    fieldset{
        border: 1px solid #E5E5E5 !important;
    }
`

export const FormWrapper = styled(FormControl)`

`;