import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	gap: 3px;

	label{
		font-size: 1rem;
		color: var(---primary-kroll-dark-font);
		font-weight: bold;
		margin-bottom: 3px;
	}
	
	input[type="date"],input[type="month"] {
		border: 1px solid #E5E5E5;
		width: 288px;
		height: 56px;
		padding: 0px 10px;
		color: #4D4D4F;
		font-size: 1rem;
		border-radius: 4px;
		font-family: Nunito Sans,sans-serif;
	}

	input[type="month"]:hover{
		cursor: pointer;
	}

`;