export function formatProduct(string) {
	const splitedArray = string.replace("_", " ").split(" ");

	const toUpperJoinedString = splitedArray.map(e => {
		const capitalString = e[0].toUpperCase() + e.slice(1);
		return capitalString;
	})

	const result = toUpperJoinedString.join(" ");
	return result;
}