import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { WrapperTrable, EmptyList, WrapperCell } from './statment.styled';

import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import Dateline from '../dateline/Dateline';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { allMonths } from 'utils/date';
import { i18n } from 'translate/i18n';
import { useTranslation } from 'react-i18next';

export default function StatmentTable({
    data,
    headers,
    handleFormatTable,
    handleSortTable,
    getCurrentCollum
}) {

    const { t } = useTranslation();
    const handleFormatDate = (date) => {

        const d = new Date(date);

        const day = d.getUTCDate();
        const month = d.getMonth();
        let finalDate = `${month} ${day}th`;
        const language = navigator.language;

        const months = allMonths();

        if (language === "en-US") {
            finalDate = `${months[month]} ${day}th`;
        } else {
            finalDate = `${day} de ${months[month]}`;
        }
        return finalDate;
    }

    return (
        <>
            {
                data.length === 0 ?
                    <EmptyList>
                        <SentimentVeryDissatisfiedIcon sx={{ fontSize: 50 }} />
                        <p>
                            {t("STATEMENT.LIST_EMPTY")}
                        </p>
                    </EmptyList> :
                    <WrapperTrable
                        component={Paper}
                        elevation={1}
                    >
                        <Table sx={{ borderBottom: "none" }}>
                            {
                                data.length > 0 &&
                                <TableHead>
                                    <TableRow>
                                        {
                                            headers.map((e) => (
                                                <WrapperCell
                                                    align="left" key={e.id}
                                                    onClick={() => {
                                                        handleFormatTable(e.id);
                                                        handleSortTable(e.name, e.sort)
                                                    }
                                                    }>
                                                    <div className='cellItem'>
                                                        {e.name}  {e.sort ? <MdOutlineKeyboardArrowUp /> :
                                                            <MdOutlineKeyboardArrowDown />}
                                                    </div>
                                                </WrapperCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                            }
                            <TableBody sx={{ borderBottom: "none" }}>
                                {
                                    data.map((row, index) => (
                                        <TableRow key={row.id}>
                                            <TableCell
                                                align="left"
                                                sx={{ padding: "0px 16px" }}
                                            >
                                                <Dateline
                                                    first={index === 0}
                                                    last={index + 1 === data.length}
                                                    date={handleFormatDate(row.dateRequest)} />
                                            </TableCell >
                                            <TableCell align="left">{row.listName}</TableCell>
                                            <TableCell align="center">{row.userName}</TableCell>
                                            <TableCell align="center" style={{ fontWeight: "bold" }}>{row.executions}</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </WrapperTrable >
            }
        </>
    );
}