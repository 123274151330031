import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const CustomAlert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const typeColors = {
    "error": "#BF0D3E",
    "success": '#43B049',
    "info": "#0288d1"
}

export default function Alert({ open, setOpen, type, text }) {
  return (
      <Snackbar  anchorOrigin={{ 
            vertical: "bottom", 
            horizontal: "center" 
        }}
        open={open} 
        autoHideDuration={3000} 
        onClose={() => setOpen(false)}
      >
        <CustomAlert 
          icon={false}
          severity={type}
          sx={{ width: '100%', minWidth: '400px' }}
          style={{
            backgroundColor: typeColors[type],
            display: 'flex',
            alignItems: "center",
            justifyContent: "center",
            borderRadius: '15px',
            opacity: 0.9,
            height: '73px'
        }}
          >
            <div style={{ margin: '0 50px 0 50px'}}>
              {text}
            </div>          
        </CustomAlert>
      </Snackbar>
  );
}