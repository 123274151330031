import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex: 1;

	.content {
		display: flex;
		flex: 1;
	}
`
