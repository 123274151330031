import styled from "styled-components";

export const Container = styled.div`
	padding: 46px;
	flex: 1;

	.cardsContainer{
		display: flex;
		flex: 1;
		justify-content: space-between;

		min-height: 210px;
		padding: 5px;

		@media (max-width:1400px) {
			gap:30px;
			min-height: 130px;
		}
	}

	.mainContent{
		display: flex;
		justify-content: center;
		flex-direction: column;
		gap: 14px;

	}

	.listWrapper {
		display: flex;
		gap: 10px;
		flex: 1;
		flex-direction: row;
		padding: 5px;
		min-height: 520px;
	}

	.buttonsContainer{
		width: 100%;
		margin-top: 20px;
		min-height: 100px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap:10px;
	}
`