import { Box } from "@mui/system";
import styled from "styled-components";

export const Logo = styled.div`
    display: flex;
    justify-content: center;
`

export const Circle = styled.div`
    width: 134px;
    height: 134px;
    border-radius: 50%;
    display: inline-block;
    background: var(---secondary-punch) 0% 0% no-repeat padding-box;
    background: #E2665C 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 70px;
    position: fixed;
    z-index: 10000;
    transform: translate(0%, -265%);
    @media(max-width: 1920px){
        font-size: 55px;
        width: 100px;
        height: 100px;  
    }
`

export const Title = styled.h1`
    color: var(---primary-kroll-blue);
    font: normal normal bold 24px/32px Nunito Sans;
    color: #14487F;
    opacity: 1;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 18px;
` 

export const Subtitle = styled.h2`
    color: var(---primary-kroll-shadow);
    text-align: left;
    font: normal normal bold 16px/22px Nunito Sans;
    letter-spacing: 0px;
    color: #4D4D4F;
`
    
export const Paragraph = styled.p`
    color: var(---primary-kroll-shadow);
    text-align: left;
    font: normal normal normal 16px/22px Nunito Sans;
    letter-spacing: 0px;
    color: #4D4D4F;
    margin-top: 18px;   
    margin-bottom: 18px;

    span{
        text-decoration: underline;
    }
`

export const Contents = styled.div`
    width: 979px;
    height: 416px;
    display: flex;
    flex-direction: column;
    aling-itens: center;
`

export const ModalWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 7
`

export const ContentWrapper = styled(Box)`
    width: 1082px;
    height: 608px;
    background: var(---primary-white) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
	background: #fff;
	border: none;
	border-radius: 8px;
	box-shadow: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    outline: none;
    position: relative;
    
    @media(max-width: 1920px){
        height: 530px;
    }

    button{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: var(---primary-white) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        color: #14487F;
        font: normal normal normal 30px Nunito Sans;
        opacity: 1;
        opacity: 1;
        position: fixed;
        transform: translate(450%, 550%);
   
        @media(max-width: 1920px){
            width: 60px;
            height: 60px;
            transform: translate(767%, 733%);
        }
    }

`