import styled from "styled-components";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

export const Wrapper = styled(Box)`
	&.MuiBox-root {
		margin-top: 20px;
		margin-bottom: 20px;
		display: flex;
		flex-direction: column;
		min-height: 55px;
	}

	& .MuiFormControl-root {
		height: 55px;
		padding: 2px;
	}

	& .MuiOutlinedInput-root {
		box-shadow: 0px 2px 4px #00000029;
		height: 48px;
		border-radius: 20px;
		background-color: #fff;
		padding-left: 10px;
	}

	& .MuiOutlinedInput-notchedOutline {
		border: none;
	}

	& .MuiInputBase-input {
		padding: 0;
	}
`;