import styled from "styled-components";

import Select from '@mui/material/Select';

export const Wrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	flex-direction: column;

	label{
		font-size: 1rem;
		color: var(---primary-kroll-dark-font);
		font-weight: bold;
		margin-bottom: 3px;
	}

`;

export const WrapperSelect = styled(Select)`
	border: 1px solid #E5E5E5;
	width: 288px;
	height: 56px;
	
	:hover{
		border: none;
	}
	
	:active{
		border:none;
	}

	:focus{
		border:none;
	}

`;