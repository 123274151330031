import { Modal } from "@mui/material";
import { t } from "i18next";
import React from "react";
import { FaQuestion } from 'react-icons/fa';
import { ContentWrapper, Circle, Contents, Paragraph, Subtitle, Title, ModalWrapper, } from "./modal-faq.styled";

export const ModalFaq = ({ isOpen, handleClose }) => {

    return (
        <>
            <Modal
                open={isOpen}
                onClose={handleClose}
            >
                <ModalWrapper>
                    <ContentWrapper>
                        <Circle>
                            <FaQuestion/>
                        </Circle>
                        <Title>{t("HOME.MODAL_FAQ.TITLE")}</Title>
                        <Contents>
                            <Subtitle>{t("HOME.MODAL_FAQ.FIRST_SUBTITLE")}</Subtitle>
                            <Paragraph>
                                {t("HOME.MODAL_FAQ.FIRST_PARAGRAPH")}            
                            </Paragraph>

                            <Subtitle>{t("HOME.MODAL_FAQ.SECOND_SUBTITLE")}</Subtitle>
                            <Paragraph>
                                {t("HOME.MODAL_FAQ.SECOND_PARAGRAPH")}
                            </Paragraph>

                            <Subtitle>{t("HOME.MODAL_FAQ.THIRD_SUBTITLE")}</Subtitle>
                            <Paragraph>
                                {t("HOME.MODAL_FAQ.THIRD_PARAGRAPH")}
                            </Paragraph>

                            <Paragraph>
                                {t("HOME.MODAL_FAQ.FOURTH_PARAGRAPH")}<span>FAQ</span>.
                            </Paragraph>
                            <button onClick={handleClose}>X</button>
                        </Contents>
                    </ContentWrapper>
                </ModalWrapper>
            </Modal>
        </>
    )
}

export default ModalFaq