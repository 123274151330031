import { SwipeableDrawer } from '@mui/material';
import { Box } from '@mui/system';


import styled from "styled-components";

export const SidebarWrapper = styled(SwipeableDrawer)`
`;

export const BoxWrapper = styled(Box)`
	width: 394px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	padding: 60px 40px;

	.topSidebar{

		gap: 20px;

		.sidebarHeader{
	
			display:flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
	
			.sidebarIcon{
				background: var(---primary-kroll);
				padding: 15px;
				border-radius: 50%;
				color: var(---primary-font-color);
	
				display:flex;
				align-items: center;
				justify-content: center;		
			}
	
			h1{
				color: var(---primary-kroll);
				font-size: 1.5rem;
			}
	
		}
	
		.sidebarBody{

			.flagsWrapper{
				display: flex;
				justify-content: center;
				flex-direction: column;

				padding: 20px 0px;

				h2{
					font-size: 1rem;
					color: var(---primary-kroll-dark-font);
					font-weight: bold;
				}

				.radioWrapper{
					display: flex;
					gap: 40px;
				}
			}

			.datesWrapper{
				display: flex;
				justify-content: center;
				flex-direction: column;

				gap: 15px;
			}

		}
	}


	.SidebarBotton{
	}
`;
