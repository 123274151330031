import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import { Wrapper, MenuValue } from './dropdown.styled'

const MenuProps = {
	PaperProps: {
		style: {
			width: 220,
			backgroundColor: "#14487F",
			borderRadius: "0px 0px 5px 5px",
		},
	},
};

export default function Dropdown({ dropdownLabel, values, selectedValue, handleDownloadTemplate }) {
	return (
		<Wrapper>
			<FormControl 
			sx={{
				width: 220,
				backgroundColor: "#14487F",
				border: "#14487F",
				borderRadius: "5px",
				height: '56px',
				transition: "ease-out 0.3s",
				":hover": {
					backgroundColor: "#003057",
				}}
			}>

				<Select
					displayEmpty
					value={selectedValue}
					renderValue={() => {
						return <div style={{ display: "flex", alignItems: "center" }}>
							<FileDownloadOutlinedIcon style={{ margin: "0 10px", color: "#ffff" }} />
							<p style={{ height: '24px' }}>{dropdownLabel}</p>
						</div>
					}}
					MenuProps={MenuProps}
					inputProps={{ 'aria-label': 'Without label', sx: { padding: '16px 14px' } }}
				>
					{values.map((name) => (
						<MenuItem
							key={name}
							value={selectedValue}
							onClick={() => {
								handleDownloadTemplate(name)
							}}
						>
							<MenuValue>{name}</MenuValue>
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Wrapper>
	);
}