import React from 'react'

//Components
import Sidebar from 'components/sidebar/Sidebar';

//Styles
import { Container } from './wrapper.styled';

export default function Wrapper({ children }) {
	return (
		<Container>
			<Sidebar />
			<div className='content'>
				{children}
			</div>
		</Container>
	)
}
