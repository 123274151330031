import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		max-width: 100vw;
		max-height: 100vh;
		overflow-y: auto;
	}

	body {
		font-family: 'Nunito Sans', sans-serif;
		background-color: #FBFBFB;	
	}

	button {
		border: none;
		cursor: pointer;
	}

	*::-webkit-scrollbar-track
	{
		-webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.2);
		background-color: #FAFAFA;
		border-radius: 4px;
	}

	*::-webkit-scrollbar
	{
		width: 8px;
		height: 8px;
		border-radius: 2px;
		background-color: #FAFAFA;
	}

	*::-webkit-scrollbar-thumb
	{
		border-radius: 4px;
		-webkit-box-shadow: inset 0 0 2px rgba(0,0,0,.3);
		background-color: #DBDBDB;
	}
`


