import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	justify-content:flex-end;

	a {
		display: flex;
		margin: 20px 40px 0 0;
		color: var(---primary-kroll);
		text-decoration: underline;
		align-items: center;
		
		svg {
			margin-right: 8px;
		}

		&:hover {
			cursor: pointer;
		}
	}
`;