import React from 'react'

//Styles
import { Wrapper } from './input.styled'

const Input = ({ labelInput, type, placeholder, value, onChange, required }) => {

	return (
		<Wrapper>
			<label>{labelInput}</label>
			<input
				type={type}
				placeholder={placeholder}
				value={value}
				onChange={onChange} 
				required={required}
				/>
		</Wrapper>
	)
}

export default Input