import api from 'config/api';
import axiosConfig from 'config/axiosConfig';

export const getCompany = async params => {
	const response = await api.get(`${axiosConfig.LOGIN_API_URL}/company`);

	return response.data.companies;
};

export const postCompany = async (data) => {
	const response = await api.post(`${axiosConfig.LOGIN_API_URL}/company`, data);

	return response.data;
};

export const putCompany = async (id, data) => {
	const response = await api.put(`${axiosConfig.LOGIN_API_URL}/company/${id}`, data);

	return response.data;
};

export const getCompanyId = async id => {
	const response = await api.get(`${axiosConfig.LOGIN_API_URL}/company/${id}`);
	console.log(response.data)
	return response.data.company;
};