import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PrivateRoutes() {
  const user = useSelector(state => state.user);

  return user.token ? <Outlet /> : <Navigate to={"/"} />
}

export default PrivateRoutes;