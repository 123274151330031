import { createGlobalStyle } from 'styled-components';

export const ColorsStyle = createGlobalStyle`
		:root{
			---primary-kroll:#14487F;
			---secondary-kroll: #003057;
			---primary-font-color:#FFFFFF;
			---primary-kroll-dark-font: #4D4D4F;
			---primary-kroll-dark-light: #4d4d4f1b;
			---danger-font-color:#BF0D3E;
			---primary-kroll-green: #43B049;
		}
`

// :root {
// 	---primary-white: #FFFFFF;
// 	---primary-kroll-shadow: #4D4D4F;
// 	---primary-kroll-green: #43B049;
// 	---secondary-punch: #E2665C;
// 	---secondary-magenta: #D00070;
// 	---secondary-lime-green: #C4D600;
// 	---secondary-forest-green: #215732;
// 	---secondary-cool-gray: #A7A8A9;
// 	---secondary-sky-blue: #4C9FC8;
// 	---secondary-ocean-blue: #67D2DF;
//
// 	---secondary-web-blue: #001424;
// 	---tertiary-pear-green: #E3E48D;
// 	---tertiary-light-blue: #BBDDE6;
// 	---tertiary-report-red: #BF0D3E;
// }