import styled from "styled-components";

export const Wrapper = styled.select`
background-color: white;
border: 1px solid white;
color: #4D4D4F;
font-size: 11px;
margin-left: 3px;

&:hover{
	cursor: pointer;
}
`;