import styled from "styled-components";

export const Wrapper = styled.div`
	h2{
		color: var(---primary-kroll-dark-font);
		font-weight: bold;
		font-size: 24px;
		margin: 20px 0px;
	}
	@media (max-width: 1400) {
		h1{
			font-size: 200px;
		}
	}
`;

export const ListWrapper = styled.div`
	box-shadow: 0px 2px 4px #00000029;
	border-radius: 15px;
	background-color: #ffff;
	margin-right: 5px;
	margin-left: 5px;

	.checkbox-cell {
		width: 47px;
	}
	
	th{
		text-align: center;
		font-weight: bold;
		font-size: 16px;
		padding: 11px;
		color:	var(---primary-kroll-dark-font);
		border: 1px solid var(---primary-kroll-dark-light);

		@media(max-width: 1400px) {
				font-size: 13px;
		}

		&:last-child{
			width: 170px;
		}
	}

	tbody{
		td {
			text-align: center;
			@media(max-width: 1400px) {
				font-size: 12px;
		}
			font-size: 16px;
			padding: 11px;
			border: 1px solid var(---primary-kroll-dark-light);
		}
	}

`;

export const EmptyList = styled.div`
	position: relative;
	height: 100%;
	min-height: 208px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	p{
		color: var(---primary-kroll-dark-font);
	}

`