import { Modal } from "@mui/material";
import Button from "components/button/Button"
import { t } from "i18next";
import React from "react";
import { BsExclamationTriangle } from 'react-icons/bs';
import { Navigate } from "react-router-dom";
import { ContentWrapper, Circle, Contents, Paragraph, Title, ModalWrapper, ButtonExit, } from "./modal-client.styled";

export const ModalClient = ({ isOpen, handleSave, handleClose }) => {

    return (
        <>
            <Modal
                open={isOpen}
                onClose={handleClose}
            >
                <ModalWrapper>
                    <ContentWrapper>
                        <Contents>
                            <Circle>
                                <BsExclamationTriangle />
                            </Circle>
                            <Title>{t("CLIENT.MODAL.TITLE")}</Title>
                            <Paragraph>{t("CLIENT.MODAL.PARAGRAPH")}</Paragraph>
                        </Contents>
                        <div className="ButtonSection">
                            <Button
                                funcButton={handleSave}
                                buttonContent={t("CLIENT.MODAL.BUTTON")}
                            />
                        </div>
                    </ContentWrapper>
                    <ButtonExit onClick={handleClose}>X</ButtonExit>
                </ModalWrapper>
            </Modal>
        </>
    )
}

export default ModalClient