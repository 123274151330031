import styled from "styled-components";
import Box from '@mui/material/Box';


export const BoxWrapper = styled(Box)`

	width: 630px;
	height: 710px;
	padding: 20px 80px;
	top: 50%;
	left: 50%;

	position: absolute;

	transform: translate(-50%, -50%);
	background: #fff;
	border: none;
	border-radius: 8px;
	box-shadow: 12px;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;	

	.MuiOutlinedInput-root {
		width: 438px;
		margin-bottom: 20px;
	}

	h1 {
		color: var(---primary-kroll-dark-font);
		font-size: 24px;
		margin-bottom: 10px;
	}

	p{
		text-align: center;
		font-size: 1rem;
		color: var(---primary-kroll-dark-font);
		padding-left: 30px;
		padding-right: 30px;
		margin-bottom: 40px;
	}

	
	.resend-code {
		color: #4D4D4F;
		font-size: 13px;
		margin-top: 10px;
		text-decoration: underline;
		cursor: pointer;
	}
`

export const ResetImage = styled.img`
	margin: 0 auto;
	width: 300px;
	height: 280px;
	margin-bottom: 20px;
`

export const ResetedImage = styled.img`
	margin: 0 auto;
	width: 380px;
	height: 400px;
	margin-bottom: 20px;
`
