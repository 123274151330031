import getEnvironment from "utils/getEnvironment";
import { ENVIRONMENT } from 'utils/environment';

const ENV = process.env.NODE_ENV;

//Login API
const DEFAULT_LOGIN_API_HOST = 'https://kimkroll-mslogin-api.azurewebsites.net'

const LOGIN_API_URL = {
  [ENVIRONMENT.PRODUCTION]: DEFAULT_LOGIN_API_HOST,
  [ENVIRONMENT.HOMOLOG]: DEFAULT_LOGIN_API_HOST,
  [ENVIRONMENT.DEVELOPMENT]: DEFAULT_LOGIN_API_HOST
}

//Home API
const DEFAULT_HOME_API_HOST = 'https://kimkroll-mshome-api.azurewebsites.net'

const HOME_API_URL = {
  [ENVIRONMENT.PRODUCTION]: DEFAULT_HOME_API_HOST,
  [ENVIRONMENT.HOMOLOG]: DEFAULT_HOME_API_HOST,
  [ENVIRONMENT.DEVELOPMENT]: DEFAULT_HOME_API_HOST
}

const config = {
  development: {
    LOGIN_API_URL: LOGIN_API_URL[getEnvironment(ENV)] || DEFAULT_LOGIN_API_HOST,
    HOME_API_URL: HOME_API_URL[getEnvironment(ENV)] || DEFAULT_HOME_API_HOST
  },
  production: {
    LOGIN_API_URL: LOGIN_API_URL[getEnvironment(ENV)] || DEFAULT_LOGIN_API_HOST,
    HOME_API_URL: HOME_API_URL[getEnvironment(ENV)] || DEFAULT_HOME_API_HOST
  }
}

export default config[ENV];