import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	gap:5px;

	label{
		color: var(primary-kroll-dark-font);
	}

	input[type="radio"] {
  box-sizing: border-box;
  appearance: none;
  background: white;
  outline: 1px solid #7c7c7c;
  border: 3px solid white;
	border-radius: 50%;
	margin-left: 5px;
  width: 16px;
  height: 16px;
		box-shadow: 0px 3px 6px #00000029;
}

input[type='radio']:checked {
  background: #14487F;
}

input[type='radio']:hover {
	cursor: pointer;
}
`