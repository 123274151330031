import React, { useState } from 'react'

//Styles
import { Wrapper } from './input-password-modal.styled';

//Incons
import { VscEye, VscEyeClosed } from 'react-icons/vsc';

const InputPassword = ({ placeholder, value, onChange, required, name }) => {

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Wrapper>
      <input
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
        required={required}
      />

      {
        !showPassword ?
          <VscEye onClick={() => setShowPassword(true)} size={25} role="icon" /> :
          <VscEyeClosed onClick={() => setShowPassword(false)} size={25} role="iconEye" />
      }

    </Wrapper>
  )
}

export default InputPassword;
