import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import { WrapperLine, WrapperTimelineConnector, WrapperTimelineDot } from './dateline.styled';

export default function Dateline({ date, first, last }) {
  return (
    <WrapperLine position="right">
      <TimelineItem sx={{
        padding: "0",
        "&::before": {
          flex: "0",
          padding: "0"
        }
      }}>
        <TimelineSeparator>
          <WrapperTimelineConnector sx={{ width: first ? '0' : '2px' }}/>
          <WrapperTimelineDot variant="filled" />
          <WrapperTimelineConnector sx={{ width: last ? '0' : '2px' }}/>
        </TimelineSeparator>
        <TimelineContent sx={{
          color: "#4D4D4F",
          display: "flex",
          fontSize: "13px",
          alignItems: "center",
          justifyContent: "center",
        }} >
          {date}
        </TimelineContent>
      </TimelineItem>
    </WrapperLine>
  );
}