import React from 'react';

// Styles
import { Wrapper } from './contact-menu.styled';

//icons
import { MdEmail } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

function ContactMenu() {
	const { t } = useTranslation()
	return (
			<Wrapper>
				<a href='https://www.kroll.com/en/contactus' target='_blank' rel="noopener noreferrer">
					<MdEmail size={30}/>
					{t('LOGIN.CONTACT_US')}
				</a>
			</Wrapper>
	);
}

export default ContactMenu;