import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Button from "components/button/Button"
import Wrapper from "components/wrapper/Wrapper"
import PreviousPage from "components/previous-page/Previous-Page"
import { t } from "i18next"
import { Content } from "./User.styled"
import { useForm } from "react-hook-form"
import { getCompany } from "services/company"
import { yupResolver } from "@hookform/resolvers/yup"
import { ModalUser } from "components/modal/modal-user/modal-user"
import DropdownUser from "components/dropdown/dropdown-user/dropdown-user"
import Input from "components/input/input-client/InputClient"
import { postUser, getUsers, getUser, putUser } from "services/user"
import Alert from "components/alert/Alert"

export const User = ({ isUpdate }) => {
    const [company, setCompany] = useState([])
    const [users, setUsers] = useState([])
    const [companyUsers, setCompanyUsers] = useState([])
    const [editData, setEditData] = useState({
        companyId: null,
        userId: null
    })
    const [modalUserOpen, setModalUserOpen] = useState(false)
    const [verifyFields, setVerifyFields] = useState(false)
    const [alert, setAlert] = useState(false)

    const navigate = useNavigate();
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const validation = yup.object({
        name: yup.string().required(t("USER.INPUTS.NAME.VALIDATION.REQUIRED")).max(25, t("USER.INPUTS.NAME.VALIDATION.MAX_LENGTH")).min(2, t("USER.INPUTS.NAME.VALIDATION.MIN_LENGTH")),
        surname: yup.string().required(t("USER.INPUTS.SURNAME.VALIDATION.REQUIRED")).max(30, t("USER.INPUTS.SURNAME.VALIDATION.MAX_LENGTH")).min(2, t("USER.INPUTS.SURNAME.VALIDATION.MIN_LENGTH")),
        email: yup.string().required(t("USER.INPUTS.EMAIL.VALIDATION.REQUIRED")).max(60, t("USER.INPUTS.EMAIL.VALIDATION.MAX_LENGTH")).min(4, t("USER.INPUTS.EMAIL.VALIDATION.MIN_LENGTH")),
        phone: yup.string().required(t("USER.INPUTS.PHONE.VALIDATION.REQUIRED")).matches(phoneRegExp, t("USER.INPUTS.PHONE.VALIDATION.MATCHES")),
        companyId: yup.string().required(t("USER.INPUTS.COMPANY.VALIDATION.REQUIRED")),
    }).required() 

    const { register, handleSubmit, watch, reset, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(validation)
    });

    const watchAllFields = watch()

    useEffect(() => {
        if (
            watchAllFields.name === '' ||
            watchAllFields.surname === '' ||
            watchAllFields.email === '' ||
            watchAllFields.phone === '' ||
            watchAllFields.companyId === "defaultValue"
        ) {
            setVerifyFields(true);
        } else {
            setVerifyFields(false);
        }
    }, [watchAllFields])


    const formatData = (data) => {
        const {
            companyId,
            name,
            surname,
            email,
            phone
        } = data;
        const result = {
            companyId: companyId,
            name: name,
            surname: surname,
            email: email,
            phone: phone
        }

        return result;
    }

    const onSubmit = (data) => {
        const formatedData = formatData(data);
        const result = isUpdate ? putUser(editData.userId, formatedData) : postUser(formatedData)

        result.then(e => {
            if (e.message === "User criado com sucesso" || e.message === 'User edited successfully') {

                setModalUserOpen(true)
                reset();

                setModalUserOpen(true)
                setAlert(true)
                setTimeout(() => {
                    setAlert(false)
                }, "5000")
            }
        })
    }

    const loadData = async () => {
        const allCompanies = await getCompany();
        setCompany(allCompanies);

        if (isUpdate) {
            const allUsers = await getUsers();
            setUsers(allUsers)
        }
    };

    const handleEditData = (e) => {
        e.preventDefault();
        const newEditData = {...editData}
        newEditData[e.target.name] = e.target.value
        setEditData(newEditData)
    }

    useEffect(() => {
        loadData()
    }, []);

    useEffect(() => {
        if (editData.companyId) {
            const filteredUsers = users.filter((item) => item.companyId === editData.companyId)
            setCompanyUsers(filteredUsers)
        }
    },[editData.companyId])

    
    useEffect(() => {
        const loadDataFromUserId = async () => {
            const selectedUser = await getUser(editData.userId)
            const fields = Object.keys(selectedUser)
            fields.forEach((field) => {
                setValue(field, selectedUser[field])
            })
        }

        if (editData.userId) {
            loadDataFromUserId()
        }
    },[editData.userId])

    return (
        <>
            <Wrapper>
                <ModalUser
                    isOpen={modalUserOpen}
                    handleClose={() => setModalUserOpen(false)}
                    isUpdate={isUpdate}
                />
                <Alert open={alert} setOpen={setAlert} type="success" text={isUpdate ? t("USER.MODAL.EDIT_TITLE") : t("USER.MODAL.TITLE")} />

                <Content>
                    <PreviousPage label={t(isUpdate ? "USER.EDIT_PREVIOUS_PAGE" : "USER.PREVIOUS_PAGE")} destiny={"/"} />
                    <div className="editUserOptions" style={{ display: isUpdate ? 'block' : 'none' }}>
                        <div className="section">
                            <DropdownUser
                                propRegister={"companyId"}
                                dropdownPlaceholder={t("USER.INPUTS.COMPANY.PLACEHOLDER")}
                                name={'companyId'}
                                company={company}
                                onChange={handleEditData}
                            />
                            <DropdownUser
                                propRegister={"userId"}
                                dropdownPlaceholder={t("USER.INPUTS.USER.PLACEHOLDER")}
                                name={'userId'}
                                company={companyUsers}
                                onChange={handleEditData}
                            />
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h1>{t("USER.TITLE")}</h1>
                        <div className='mainContent'>
                            <div className="section">
                                <Input
                                    errors={errors?.name}
                                    register={register}
                                    labelInput={t("USER.INPUTS.NAME.LABEL")}
                                    placeholder={t("USER.INPUTS.NAME.PLACEHOLDER")}
                                    name={'name'}
                                    disabled={isUpdate && !editData.userId}
                                />
                                <Input
                                    errors={errors?.surname}
                                    register={register}
                                    labelInput={t("USER.INPUTS.SURNAME.LABEL")}
                                    placeholder={t("USER.INPUTS.SURNAME.PLACEHOLDER")}
                                    name={'surname'}
                                    disabled={isUpdate && !editData.userId}
                                />
                                <Input
                                    errors={errors?.email}
                                    register={register}
                                    labelInput={t("USER.INPUTS.EMAIL.LABEL")}
                                    placeholder={t("USER.INPUTS.EMAIL.PLACEHOLDER")}
                                    name={'email'}
                                    type={'email'}
                                    disabled={isUpdate && !editData.userId}
                                />
                                <Input
                                    register={register}
                                    errors={errors?.phone}
                                    labelInput={t("USER.INPUTS.PHONE.LABEL")}
                                    placeholder={t("USER.INPUTS.PHONE.PLACEHOLDER")}
                                    name={'phone'}
                                    disabled={isUpdate && !editData.userId}
                                />
                                <DropdownUser
                                    register={register}
                                    errors={errors?.companyId}
                                    propRegister={"companyId"}
                                    label={t("USER.INPUTS.COMPANY.LABEL")}
                                    dropdownPlaceholder={t("USER.INPUTS.COMPANY.PLACEHOLDER")}
                                    name={'company'}
                                    company={company}
                                    style={{ display: isUpdate ? 'none' : 'block' }}
                                    disabled={isUpdate && !editData.userId}
                                />
                            </div>
                        </div>
                        <div className="ButtonSection">
                            <Button
                                buttonType={'button'}
                                funcButton={() => navigate("/")}
                                buttonContent={t("USER.BUTTON.CANCEL")}
                            />
                            <Button
                                buttonType={'submit'}
                                buttonContent={t("USER.BUTTON.SAVE")}
                                disabledProp={verifyFields ? true : false}
                            />
                        </div>
                    </form>
                </Content>
            </Wrapper>
        </>
    )
}

export default User

