import React from "react";
import { CircularProgress } from "@mui/material";

import { Container } from './loader.styled';

const Loader = (props) => {

  const { visible } = props;

  return visible ? (
    <Container >
      <CircularProgress />
    </Container>
  ) : null;
};

export default Loader;
