import React from 'react';

// Styles
import { Wrapper } from './button.styled';

function Button({ buttonContent, buttonType, disabledProp, children, funcButton, widthProp}) {

	return (
		<>
			<Wrapper type={buttonType} disabled={disabledProp} onClick={funcButton} style={{width:widthProp || '220px'}}>
				{children}
				{buttonContent}
			</Wrapper>
		</>
	);
}

export default Button;