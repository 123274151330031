import * as React from 'react';
import Modal from '@mui/material/Modal';

//Styles
import { Wrapper, ModalContent } from './modal-exit.styled';

//Components
import Button from 'components/button/Button';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { i18n } from 'translate/i18n';
import { useTranslation } from 'react-i18next';


export default function ModalExit({ isOpen, handleClose, onConfirm, modalTitle, modalBody, modalBodyStrong }) {
	const { t } = useTranslation();

	return (
		<>
			<Modal
				open={isOpen}
				onClose={handleClose}
			>
				<Wrapper>
					<WarningAmberIcon />
					<ModalContent>

						<div className='modalContent'>
							<h1>{modalTitle}</h1>
							<p>{modalBody}<br />
								<strong>{modalBodyStrong}</strong>
							</p>
							<div className='buttonWrapper'>
								<Button
									variant="raised"
									component="span"
									widthProp={"220px"}
									funcButton={handleClose}
								>
									{t("LOGOUT_MODAL.CANCEL_BUTTON")}
								</Button>

								<Button variant="raised" component="span" widthProp={"220px"} funcButton={onConfirm} >
									{t("LOGOUT_MODAL.CONFIRM_BUTTON")}
								</Button>
							</div>
						</div>
					</ModalContent>
				</Wrapper>
			</Modal>
		</>
	);
}