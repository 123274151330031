import styled from "styled-components";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const Wrapper = styled.div`
	display:flex;
	align-items: center;
	margin-bottom: 20px;
	min-height: 80px;
	p{
		font-size:2rem;
		font-weight: bold;
		color:var(---primary-kroll);
	}
	`;

export const StyledArrow = styled(ArrowBackIcon)`
	color:var(---primary-kroll);
	margin-right: 16px;

	:hover{
		cursor: pointer;
	}
`;