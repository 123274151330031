import React from 'react'

//Styles
import { Wrapper, ListWrapper, EmptyList } from './list-container.styled'

//Icons
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

//Components
import {
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper
} from '@mui/material';

import { i18n } from "../../translate/i18n";
import CheckboxCell from 'components/checkbox-cell/CheckboxCell';
import ColumnTable from './column-table/Column-table';
import { useTranslation } from 'react-i18next';

export default function ListContainer(
	{ title,
		columns,
		data,
		wrapperHeight,
		heightProp,
		widthProp,
		wrapperWidth,
		selectable,
		handleSelectItem,
		handleSelectAllItems
	}) {
	const { t } = useTranslation();

	const getHeadersFromColumns = (item, index) => {
		return (<TableCell key={index}>{t(item.label) || ''}</TableCell>)
	}

	const getDataCell = (item, name, type, key) => {
		return (type ?
			<ColumnTable type={type} key={key} value={item} name={name} />
			: <TableCell key={key}>{item[name] || ''}</TableCell>)
	}

	const getRowFromData = (item, index) => {
		const key = item.id || index
		return (
			<TableRow key={key}>
				{selectable && (
					<TableCell className="checkbox-cell" key={key}>
						<CheckboxCell
							onChange={() => handleSelectItem(key)} checked={item.selected}
						/>
					</TableCell>
				)}
				{columns.map((column, index) => getDataCell(item, column.name, column.type, (index + 1)))}
			</TableRow>
		)
	}

	return (
		<Wrapper style={{ height: wrapperHeight || "300px", width: wrapperWidth || "100%" }}>
			{title && (<h2>{title}</h2>)}
			<ListWrapper sx={{ height: heightProp || "208px" }}>
				{
					data.length === 0 ?
						(
							<EmptyList>
								<SentimentVeryDissatisfiedIcon sx={{ fontSize: 50 }} />
								<p>{t("HOME.LISTS.EMPTY_LIST")}</p>
							</EmptyList>
						) :
						(
							<TableContainer
								component={Paper}
								sx={{ height: heightProp || "208px", overflow: 'auto', width: widthProp || "100%" }}
								>
								<Table stickyHeader={true} aria-label='simple table' size={'small'}>
									<TableHead>
										<TableRow>
											{selectable && (
												<TableCell className="checkbox-cell">
													<CheckboxCell
														onChange={() => handleSelectAllItems((data.filter(item => item.selected).length !== data.length))}
														checked={data.filter(item => item.selected).length === data.length} />
												</TableCell>
											)}
											{columns.map(getHeadersFromColumns)}
										</TableRow>
									</TableHead>
									<TableBody>
										{data.map(getRowFromData)}
									</TableBody>
								</Table>
							</TableContainer>
						)
				}
			</ListWrapper>
		</Wrapper >
	)
}
