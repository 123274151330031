import styled from "styled-components";

export const Content = styled.main`
	padding: 46px;
	flex: 1;
	display: flex;
	flex-direction: column;

	.mainContent{
		display: flex;
		flex-direction: column;
		gap: 20px;
		flex: 1;
	}
	.executionsWrapper{
		p{
			color: #4D4D4F;
			letter-spacing: 0px;
		}
	}
	.statmentTable{
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 2px;
		overflow: hidden;
	}
`;

export const ButtonsWrapper = styled.div`

	display: flex;
	align-items: center;
	gap: 20px;
	height: 100px;

	button{
		margin-top: 27px;
	}

	.btnUpload {
		display: flex;
		align-items: center;
		justify-content: space-around;
		padding: 0px 15px;

		p {
			font-weight: normal;
		}
	}
`;