import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
	Wrapper,
	ButtonsWrapper
} from './pre-home.styled';

import imgLogo from '../../assets/imgLogo.png';
import Button from 'components/button/Button';

import { i18n } from "../../translate/i18n";
import Sidebar from 'components/sidebar/Sidebar';
import { useTranslation } from 'react-i18next';

function PreHome() {
	const { user } = useSelector(state => state);
	const { t } = useTranslation();

	return (
		<Wrapper>
			<Sidebar />
			<img src={imgLogo} alt="Logo Kroll" />
			<h1>
				{t('PRE_HOME.WELCOME')}, <strong>{user.name}!</strong> {t('PRE_HOME.WELCOME_MESSAGE')} <br />
			</h1>
			<ButtonsWrapper>
				<Link to={'/home'}><Button buttonType={'button'} buttonContent={'Due Diligence'} /></Link>
				<Button buttonType={'button'} buttonContent={'Financial'} />
			</ButtonsWrapper>
		</Wrapper>
	);
}

export default PreHome;