import { SET_USER, REMOVE_USER } from '../types';

let storageUser = JSON.parse(localStorage.getItem('@KimProject:user'));

const INITIAL_STATE = storageUser ? storageUser : {
    user: {
        name: '',
        surname: '',
        email: '',
        profile: '',
        phone: '',
        role: '',
        company: {},
        language: ''
    },
    token: '',
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...action.payload,
            }
        case REMOVE_USER:

            return {
                name: '',
                surname: '',
                email: '',
                profile: '',
                phone: '',
                role: '',
                company: {},
                language: ''
            }
        default:
            return state;
    }
}

export default reducer;