import styled from "styled-components";

export const Wrapper = styled.div`
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		width: 450px;

		label{
			color: var(---primary-kroll-dark-font);
			font-weight: bold;
			margin-bottom: 6px;
		}

		input{
			width: 450px;
			height: 64px;

			border: 1px solid #E5E5E5;
			border-radius: 5px;
			padding-left: 16px;
			color: var(---primary-kroll-dark-font);
			font-size: var(---text-font-size);
      margin-bottom: 20px;

			:focus{
				outline: none;
			}

			::-ms-reveal,::-ms-clear{
				display: none;
			}
		}

		svg{
			width: 60px;
      position:absolute;
			align-self: flex-end;
			margin-top: 20px;
			margin-left: 10px;

			color: var(---primary-kroll-dark-font);

			cursor: pointer;
		}
`;