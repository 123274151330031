import api from 'config/api';
import axiosConfig from 'config/axiosConfig';

export const getStatment = async (dateRequest, companyId) => {
	const response = await api.get(`${axiosConfig.HOME_API_URL}/extract`, {
		params: {
			dateRequest, companyId
		}
	});

	return response.data;
};

export const downloadStatment = async (dateRequest, companyId) => {
	const response = await api.get(`${axiosConfig.HOME_API_URL}/extract/download`, {
		params: {
			dateRequest, companyId
		}
	});

	const url = URL.createObjectURL(new Blob([response.data]))
	const link = document.createElement("a");
	link.download = 'kroll-statement.csv';
	link.href = url;
	link.click();
};