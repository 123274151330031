import api from 'config/api';
import axiosConfig from 'config/axiosConfig';

export const getUser = async id => {
	const response = await api.get(`${axiosConfig.LOGIN_API_URL}/user/${id}`);

	return response.data.user;
};

export const getUsers = async params => {
	const response = await api.get(`${axiosConfig.LOGIN_API_URL}/user`);

	return response.data.users;
};

export const postUser = async (data) => {
	const response = await api.post(`${axiosConfig.LOGIN_API_URL}/user`, data);

	return response.data;
};

export const putUser = async (userId, data) => {
	const response = await api.put(`${axiosConfig.LOGIN_API_URL}/user/${userId}`, data);

	return response.data;
};