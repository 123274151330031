export function formatDate(date) {
	return new Date(date).toLocaleDateString({
		year: "numeric",
		month: "numeric",
		day: "numeric",
	})
}

export function getMonth(date) {
	return new Date(date).toLocaleDateString({
		year: "numeric",
		month: "text",
		day: "numeric",
	})
};

export const allMonths = () => {

	const language = (navigator.language || navigator.browserLanguage);
	let months;

	if (language === "en-US") {
		months = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December"
		];
	} else {
		months = [
			"Janeiro",
			"Fevereiro",
			"Março",
			"Abril",
			"Maio",
			"Junho",
			"Julho",
			"Agosto",
			"Setembro",
			"Outubro",
			"Novembro",
			"Dezembro"];
	}

	return months;
}