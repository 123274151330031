import styled from "styled-components";

export const Wrapper = styled.div`
	@keyframes slow-fade-in {
		from {opacity: 0;}
		to {opacity: 1;}
	}

	position: sticky;
	background: #FFFFFF;
	top: 0px;
	left: 0px;
	height: 100vh;
	overflow: none;


	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 30px 0px;

	box-shadow: 0px 3px 6px #00000029;
	border: 1px solid #E5E5E5;

	transition:	all 0.6s ease-in-out;

	.sidebar-header-info{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		opacity: 1;
		animation-name: slow-fade-in;
		animation-duration: 1.5s;
	}

	.products::first-letter{
		text-transform: capitalize;
	}
`;

export const SidebarHeader = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	padding-bottom: 10px;
	flex-direction: column;

`;

export const ProfileInfo = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	padding-bottom: 10px;

	
	svg {
		color: var(---secondary-kroll);
	}

	p {
		margin-left: 8px;
		margin-right: 8px;
		font-weight: light;
		font-size: 11px;
		color: var(---primary-kroll-dark-font);
	}

	p:first-child {
		font-weight: 600;
		font-size: 13px;
	}

`

export const SidebarBottom = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	color: var(---primary-kroll);
	transition: all 1.5s ease-in-out;

	.expand-button-sidebar:hover {
		color: red;
	}
	
	span {
		display: flex;
		padding: 6px 10px;
		min-height: 40px;
		align-items: center;
		justify-content: center;

		:hover {
			cursor: pointer;
			color:	var(---secondary-kroll);

			p {
				color:	var(---secondary-kroll);
			}
		}

		p {
			opacity: 1;
			animation-name: slow-fade-in;
			animation-duration: 1s;
			color: var(---primary-kroll-dark-font);
			font-weight: bolder;
		}
	}

	svg {
		margin-right: 7px;
		font-size: 1.5rem;
	}

	img {
		margin-right:	7px;
	}
`;

export const ProfilePhotoThumb = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 48px;
	height: 48px;
	border-radius: 24px;
	box-shadow: 0px 2px 4px #00000029;
`;

export const KrollIcon = styled.img`
	width: 36px;
	height: 36px;
`;