import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: [
        'Nunito Sans',
        'sans-serif'
      ].join(','),
    },
  },
  components: {
    MuiTimelineSeparator: {
      styleOverrides: {
        root: {
          overflowY: 'visible'
        }
      }
    },
    MuiTimelineDot:{
      styleOverrides: {
        root: {
          color: 'blue'
        }
      }
    }
  }
});

export default theme
