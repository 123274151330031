import styled from "styled-components";
import { Box } from '@mui/material';

export const Container = styled(Box)`
  background: rgba(256, 256, 256, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000; 
`;
