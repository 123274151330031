import styled from "styled-components";

export const Wrapper = styled.div`
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	h1{
		margin: 30px 0;
		text-align: center;
		color: var(---primary-kroll-dark-font);
	}
	strong{
		color: var(---primary-kroll);
	}
`;

export const ButtonsWrapper = styled.div`
	display: flex;

	button{
		margin-right: 30px;
	}
`;