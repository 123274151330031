import styled from "styled-components";

export const Content = styled.div`
    padding: 46px;
	flex: 1;
    display: flex;
    flex-direction: column;

    .MuiList-root {
        max-height: 200px;
    }

    h1{
        margin-top: 20px;

        @media(min-width: 1800px){
            margin-top: 100px;
        }
    }

    h1, h2, h3{
        color:var(---primary-kroll);
        text-align: left;
        font: normal normal bold 16px/22px Nunito Sans;
        letter-spacing: 0px;
        color: #14487F;
        opacity: 1;
    }

    h2{
        margin-top: 32px;
        margin-bottom: 24px;
    }

    h3{
        margin-top: 30px;
        margin-bottom: 20px;
    }


    form{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex: 1;
    }

    .mainContent {
        flex: 1;
    }

    .section{
        display: grid;
        grid-template-columns: repeat(3, 4fr);
        grid-column-gap: 24px;
        gap: 24px;
        margin-top: 24px;

        @media(min-width: 1800px){
            grid-template-columns: repeat(5, 2fr);
            gap: 20px;
        }
    }

    .section2{
        display: flex;
    }

    .ButtonSection {
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 24px;
    }
`