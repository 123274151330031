import styled from "styled-components";

export const Wrapper = styled.div`
	cursor: pointer;
	display: flex;
	width: 30px;
	align-items: center;
	justify-content: center;
`;

export const Input = styled.input.attrs({ type: 'checkbox' })`
	cursor: pointer;
	-moz-appearance:none;
	-webkit-appearance:none;
	-o-appearance:none;
	appearance: none;
	transform: translateY(-0.075em);
	width: 24px;
  height: 24px;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border-radius: 5px;
	display: grid;
  place-content: center;
	&::before {
		content: "";
		width: 18px;
		height: 18px;
		transform: scale(0);
		transition: 120ms transform ease-in-out;
		box-shadow: inset 18px 18px var(---primary-kroll);
		border-radius: 0.1rem;
		transform-origin: bottom left;
  	clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
	}
	&:checked::before {
		transform: scale(1);
	}

`;

export const Label = styled.label`
	cursor: pointer;
	color: var(---primary-kroll-dark-font);
`;