import styled from "styled-components";
import TableContainer from '@mui/material/TableContainer';
import { TableCell } from "@mui/material";

export const WrapperTrable = styled(TableContainer)`

	width: 798px;
	height: 491px;

	tr{
		th{
			text-align: center;
			font-size: 14px;
		}
	}

`;


export const WrapperCell = styled(TableCell)`
	&.MuiTableCell-root{
		width: 46px;
		border: 1px solid rgba(224, 224, 224, 1);
	}
`;