import React from 'react';

import { Wrapper } from './dropdown-products.styled'

export default function DropdownProducts({ values }) {
	return (
		<Wrapper>
			<option>
				{values[0]}
			</option>
		</Wrapper>
	);
}