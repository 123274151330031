import React from 'react'
import { US, BR } from 'country-flag-icons/react/3x2'
//Styles
import { Wrapper } from './Region-Flags.styled'
import { useTranslation } from 'react-i18next'

const RegionFlags = ({ label, nameValue, valueState, onChangeFunction }) => {
	const { i18n } = useTranslation()

	const selectedDefaultLanguage = localStorage.getItem('@KimProject:language') || i18n.language

	const isActiveLanguage = (lng) => lng === selectedDefaultLanguage ? '' : ' inactive'

	const setSelectedLanguage = (lng) => {
		i18n.changeLanguage(lng);
		localStorage.setItem('@KimProject:language', lng)
	}

	return (
		<Wrapper>
			<US title="United States" className={"us-region-flag"+isActiveLanguage('en-US')} onClick={() => {setSelectedLanguage('en-US'); }}/>
			
			<BR title="Brazil" className={"br-region-flag"+isActiveLanguage('pt-BR')} onClick={() => {setSelectedLanguage('pt-BR');}}/>
		</Wrapper> 
	)
}

export default RegionFlags