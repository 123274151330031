import { combineReducers } from 'redux';

import alert from './alert.reducer';
import loader from './loader.reducer';
import user from './user.reducer'
import home from './home.reducer'

export default combineReducers({
    alert,
    loader,
    user,
    home
})