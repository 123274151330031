import styled from "styled-components";
import FlagIcon from '@mui/icons-material/Flag';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { TableCell } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

export const Wrapper = styled(TableCell)`
	.index{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0px 10px;
	}

	.wrapperExecuted {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0px 10px;
	}

	.flagContainer {
		display: flex;
		align-items: center;
	}
`;

export const GreenFlag = styled(FlagIcon)`
	color: var(---primary-kroll-green);
	padding-top: 3px;
`;
export const BlackFlag = styled(FlagIcon)`
	color: #000000;
	padding-top: 3px;
`;
export const RedFlag = styled(FlagIcon)`
	color: #FF0000;
	padding-top: 3px;
`;

export const DetailsIcon = styled(OpenInNewIcon)`
	color: var(---primary-kroll);

	:hover{
		cursor: pointer;
	}
`;

export const RefreshIconStyled = styled(RefreshIcon)`


`;