import * as React from 'react';

import { Wrapper, CardContainer } from './card.styled';

export default function Card({ cardTitle, icon, type, content, isRedFlag }) {
	return (
		<Wrapper elevation={0}>
			<CardContainer>
				<div className='headerContent'>
					<p>{cardTitle}</p>{icon}
				</div>
				<div className='cardContent'>
					<p style={isRedFlag ? { color: "#BF0D3E" } : { color: "#14487F" }}>{type}</p>
					<h2 style={isRedFlag ? { color: "#BF0D3E" } : { color: "#14487F" }}>{content}</h2>
				</div>
			</CardContainer>
		</Wrapper>
	);
}