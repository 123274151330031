import React, { useState } from 'react'

//Styles
import { Wrapper } from '../input.styled';

//Incons
import { VscEye, VscEyeClosed } from 'react-icons/vsc';

const InputPassword = ({ labelInput, placeholder, value, onChange, required }) => {

	const [showPassword, setShowPassword] = useState(false);

	return (
		<Wrapper>
			<label>{labelInput}</label>

			<input
				type={showPassword ? "text" : "password"}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				required={required}
			/>

			{
				!showPassword ?
					<VscEye onClick={() => setShowPassword(true)} size={25} role="icon" /> :
					<VscEyeClosed onClick={() => setShowPassword(false)} size={25} role="iconEye" />
			}

		</Wrapper>
	)
}

export default InputPassword;
