import { ENVIRONMENT } from './environment';

const getEnvironment = (ENV) => {
  if (ENV === ENVIRONMENT.PRODUCTION) {
    if (window.location.host === 'admin.oms.gpa.digital') {
      return ENVIRONMENT.PRODUCTION;
    } else if (window.location.host === 'admin-hlg.oms.gpa.digital') {
      return ENVIRONMENT.HOMOLOG;
    } else {
      return ENVIRONMENT.DEVELOPMENT;
    }
  } else {
    return ENVIRONMENT.DEVELOPMENT
  }
}

export default getEnvironment;