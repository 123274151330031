const messages = {
	en: {
		translations: {
			FORGOT_PASSWORD: {
				EMAIL: {
					TITLE: 'Forgot your password?',
					BODY: "Enter your e-mail address and we'll send you a code to reset your password.",
					INPUT_PLACEHOLDER: "Enter your e-mail address...",
					SUBMIT_BUTTON: "Send",
				},
				CODE: {
					TITLE: 'Check-in your email',
					BODY: "We've sent you an e-mail containing a code to reset your password.",
					INPUT_PLACEHOLDER: "Enter the verification code...",
					SUBMIT_BUTTON: "Next",
					NEW_CODE: "Didn't receive our code? Send again",
					NEW_CODE_SENDED: "A new code was sent. A new code can be sent again in"
				},
				PASSWORD: {
					TITLE: 'Reset your password',
					BODY: 'Type in your new password below:',
					PASSWORD_PLACEHOLDER: 'Enter your new password...',
					CONFIRM_PASSWORD_PLACEHOLDER: 'Confirm your new password...',
					SUBMIT_BUTTON: "Next",
					TITLE_FIRST_ACCESS: "Welcome to Kim App!",
				},
				RESETED: {
					TITLE: 'Password reseted',
					BODY: 'Your password was successfully updated!',
					SUBMIT_BUTTON: 'Finish',
				}
			},
			LOGIN: {
				TITLE: 'Hello, welcome to the',
				TITLE_STRONG: "KIM Application",
				SUB_TITTLE: "Please enter your credentials to begin",

				EMAIL_PLACEHOLDER: "Type in your e-mail... ",
				PASSWORD_LABEL: "PASSWORD",
				PASSWORD_PLACEHOLDER: "Enter your password...",
				REMEMBER_ME: "Remember me",
				LOGIN_BUTTON: "Login",
				FORGOT_PASSWORD: "Forgot or lost your password?",

				ERROR_MESSAGE: "Your e-mail or password is incorrect. Please check your",
				SUB_ERROR_MESSAGE: "credentials and try again.",

				CONTACT_US: 'Contact Us'

			},
			LOGOUT_MODAL: {
				TITLE: "About to exit the application!",
				BODY: "Please make sure there are not any unsaved changes.",
				BODY_STRONG: "Are you sure you want to exit?",
				CANCEL_BUTTON: "Cancel",
				CONFIRM_BUTTON: "Confirm"
			},
			PRE_HOME: {
				WELCOME: 'Welcome',
				WELCOME_MESSAGE: 'In order to get started, please choose between our two tools below.',
			},
			SIDEBAR: {
				OFF_KIM: "Adverse Media",
				EDIT_CLIENT: "Edit Client",
				NEW_CLIENT: "New Client",
				EDIT_USER: "Edit User",
				NEW_USER: "New User",
				FAQ: "FAQ",
				COLLAPSE: "Collapse",
				EXTRACT: "Statement (D.D)",
				EXIT: "Exit",

				MODAL_OKFF_KIM_TITLE: "You're about acess an external link!",
				MODAL_OKFF_KIM_BODY: "Please make sure there are not any unsaved changes.",
				MODAL_OKFF_KIM_BODY_STRONG: "Are you sure you want to exit?",
			},
			HOME: {
				SEARCH_INPUT_PLACEHOLDER: "Search for a specific subject...",
				LIST_LABEL: "Lists",
				DOWNLOAD_DROPDOWN: "Download",
				COMPANY: "Company (CNPJ)",
				INDIVIDUAL: "Individual (CPF)",
				UPLOAD_NEW_LIST: "Upload new lists",
				EXECUTED: "Executed",
				ON_EXECUTION: "On Execution",
				EXECUTION_QUEUE: "Execution Queue",
				DOWNLOAD_ALERT: "was downloaded successfully",
				FILTERS_APPLIED: "Filters applied successfully",
				UPLOAD_ALERT: "was uploaded successfully",
				FILE: "File",

				LISTS: {
					EMPTY_LIST: "This list seems to be empty. Please upload a list to display data.",
					CLIENT_NAME: "Client Name",
					NAME_LIST: "List Name",
					CATEGORY: "Category",
					SUBJECTS: "Amount of Subjects",
					REQUEST_DATE: "Request Date",
					FINISH_DATE: "Completion Date",
					USER_NAME: "Requested By"
				},

				MODAL_FAQ: {
					TITLE: "How to use this tool?",
					FIRST_SUBTITLE: "Getting started",
					FIRST_PARAGRAPH: "If this is your first access, your home page should be displaying no data at all. In order to display information and start analyzing data, you must download a template and upload it to the system, there are two buttons in the home page regarding this functionality.",
					SECOND_SUBTITLE: "How to download a list template?",
					SECOND_PARAGRAPH: "There's a dedicated download button in the home page, click it and select from the dropdown list, your download will start automatically. After succesfully downloading the material, fill in all the necessary fields and upload it to the system.",
					THIRD_SUBTITLE: "How to upload a list?",
					THIRD_PARAGRAPH: `To upload a list to the system, please make sure you have downloaded the template in the first place. Go to the home page and click the upload new list button, you will be prompted with a pop-up window. Select "Run only once" if you'd like to run that list just one time, otherwhise; select "Recurrence" and multiple parameters should appear on your screen, those options will determine how many times this list will run and for how long. `,
					FOURTH_PARAGRAPH: `In case you need assistance with anything else, please refer to the`,
				},

				MODAL: {
					LABEL_FIRST_TEXT: `If you'd like to run the list in a cycle please choose the "Recurrence" option, whereas by opting to "Run only once" the uploaded list will be executed only one time.`,

					RADIO_RUN_ONLY_ONCE: "Run only once",
					RECURRENCE: "Recurrence",
					RECURRENCE_MONTH: "Monthly",
					RECURRENCE_QUARTERLY: "Quarterly",
					RECURRENCE_SEMI: "Semiannual",
					RECURRENCE_ANN: "Annually",

					HOW_MANY_TIMES_RUN: "How many times will this list run?",
					WILL_RUN_UNTIL: "This list will run until:",

					SECOND_lABEL_FIRST: "Please select the desired list by clicking the upload button below. Refer to the FAQ for supported formats, upload rules and more. Select the list and click submit to start the process.",

					SELECT_FILE: "Select a file",
					SUBMIT_BUTTON: "Submit",
				},

				FILTER_SIDEBAR: {
					SIDEBAR_TITLE: "Filters",
					SUB_TITLE: "Flags",
					BLACK_FLAG: "Black",
					RED_FLAG: "Red",
					GREEN_FLAG: "Green",

					REQUEST_DATE: "Resquest Date",
					CONCLUSION_DATE: "Conclusion Date",
					REQUESTED_BY: "Requested By",
					REQUESTED_BY_PLACEHOLDER: "Select a client",
					BUTTON_APPLY: "Apply",
				},

				COLUMN_TABLE: {
					RECORRENCE_OF: "of",
					PROCESSING: 'Processing...'
				}
			},
			LIST_DETAIL: {
				LABEL: "List Details",
				CLIENT_NAME: "Client Name",
				SUBJECT_NAME: "Subject Name",
				USER_INFO: "Document",
				LIST_NAME: "List Name",
				QTD_FLAGS: "Flag QTY",
				DOWNLOAD_PDF: "Download PDF",
				CARD_SEARCH_SUBJECTS: "Subjects searched",
				CARD_RED_FLAGS_SUBJECTS: "Subjects w/ Red Flags",
				CARD_QTD_RED_FLAGS: "QTY of Red Flags",
				CARD_MOST_RED_FLAGS: "Category with most Red Flags",
				LIST_EXPAND_TITLE_CATEGORY: "Categories",
				LIST_EXPAND_TITLE_QTD_FLAGS: "QTY of Flags",
				LIST_DASHBOARD_TITLE_SUBJECT_NAME: "Subject Name",
				LIST_DASHBOARD_DOC: "Document",
				LIST_DASHBOARD_TITLE_QTD_FLAGS: "QTY of Flags",
				LIMIT_SUBJECTS_TEXT: "Limit of 20 selected subjects reached",
			},
			RED_FLAGS: {
				CATEGORY: {
					PEP: "PEP",
					NATIONALSANCTIONS: "Sanções Nacionais",
					INTERNATIONALSANCTIONS: "Sanções Internacionais",
					OFFSHORE: "Offshore",
					CRIMINALPROCESS: "Processos Criminais",
					OTHERSJUDICIALPROCESS: "Outros Processos Judiciais",
					POLITICALDONATIONS: "Doações Políticas",
					ADVERSESMIDIAS: "Mídias Adversas",
				},

				TYPE: {

				}
			},

			CLIENT: {
				PREVIOUS_PAGE: "New Client",
				EDIT_PREVIOUS_PAGE: "Edit Client",
				TITLE: "Basic information",
				SUB_TITLE: "Products purshased*",
				SECOND_SUB_TITLE: "Purchased plan (Due Diligence*)",
				ALERT_MESSAGE: "Client created succesfully",
				EDIT_ALERT_MESSAGE: "Client edited succesfully",
				INPUTS: {
					COMPANY: {
						LABEL: "Company*",
						PLACEHOLDER: "Type in the company name",
						VALIDATION: {
							REQUIRED: 'The company field is required!',
							MIN_LENGTH: 'The company must contain at least 2 characters.',
							MAX_LENGTH: 'The company must be less than 45 characters',
						},
					},
					DOCUMENT: {
						LABEL: "CNPJ",
						PLACEHOLDER: "Type in the CNPJ",
					},
					EMAIL: {
						LABEL: "E-mail*",
						PLACEHOLDER: "Type in a valid e-mail",
						VALIDATION: {
							REQUIRED: 'The e-mail field is required!',
							MIN_LENGTH: 'The e-mail must contain at least 3 characters.',
							MAX_LENGTH: 'The e-mail must be less than 60 characters',
						},
					},
					PHONE: {
						LABEL: "Phone*",
						PLACEHOLDER: "Type in a phone number",
						VALIDATION: {
							REQUIRED: 'The phone field is required!',
							MATCHES: 'Phone number is not valid',
						},
					},
					COUNTRY: {
						LABEL: "Country",
						PLACEHOLDER: "Select a country",
					},
					STATE: {
						LABEL: "State",
						PLACEHOLDER: "Select a state",
					},
					CITY: {
						LABEL: "City",
						PLACEHOLDER: "Select a city",
					},
					DISTRICT: {
						LABEL: "District",
						PLACEHOLDER: "Type in the district",
					},
					STREET: {
						LABEL: "Street",
						PLACEHOLDER: "Type in the street",
					},
					NUMBER: {
						LABEL: "Number",
						PLACEHOLDER: "Type in the house number",
					},
				},
				BUTTON: {
					CANCEL: "Cancel",
					SAVE: "Save",
				},
				MODAL: {
					TITLE: "Create a new user",
					PARAGRAPH: "Would you like to create a new user associated to this client?",
					BUTTON: "Create new user",
				},
				ALERT: {
					TITLE: "Client created successfully",
					EDIT_TITLE: "Client updated successfully"
				},
				PURCHASED_DD: {
					PLACEHOLDER: "Select the number of executions",
					FIRST_OPTION: "Up to 50 executions",
					SECOND_OPTION: "Between 51 and 150 executions",
					THIRD_OPTION: "Between 151 and 500 executions",
					FOURTH_OPTION: "500+ executions",
				},
			},

			USER: {
				EDIT_PREVIOUS_PAGE: "Edit User",
				PREVIOUS_PAGE: "New User",
				TITLE: "Basic information",
				INPUTS: {
					NAME: {
						LABEL: "Name*",
						PLACEHOLDER: "Type in the person's name",
						VALIDATION: {
							REQUIRED: 'The name field is required!',
							MIN_LENGTH: 'The name must contain at least 2 characters.',
							MAX_LENGTH: 'The name must be less than 30 characters',
						},
					},
					SURNAME: {
						LABEL: "Surname*",
						PLACEHOLDER: "Type in the person's surname",
						VALIDATION: {
							REQUIRED: 'The surname field is required!',
							MIN_LENGTH: 'The surname must contain at least 2 characters.',
							MAX_LENGTH: 'The surname must be less than 30 characters',
						},
					},
					EMAIL: {
						LABEL: "E-mail*",
						PLACEHOLDER: "Type in a valid e-mail",
						VALIDATION: {
							REQUIRED: 'The e-mail field is required!',
							MIN_LENGTH: 'The e-mail must contain at least 4 characters.',
							MAX_LENGTH: 'The e-mail must be less than 60 characters',
						},
					},
					PHONE: {
						LABEL: "Phone*",
						PLACEHOLDER: "Type in a phone number",
						VALIDATION: {
							REQUIRED: 'The phone field is required!',
							MATCHES: 'Phone number is not valid',
						},
					},
					COMPANY: {
						LABEL: "Company*",
						PLACEHOLDER: "Select the company",
						VALIDATION: {
							REQUIRED: 'The company field is required!',
						},
					},
					USER: {
						LABEL: "User*",
						PLACEHOLDER: "Select the user",
					},
				},
				BUTTON: {
					CANCEL: "Cancel",
					SAVE: "Save",
				},
				MODAL: {
					TITLE: "User created successfully!",
					EDIT_TITLE: "User edited successfully!",
					FIRST_PARAGRAPH: "Would you like to create a new user?",
					FIRST_BUTTON: "Yes, create new user",
					SECOND_BUTTON: "No, create only one user",
					EDIT_FIRST_PARAGRAPH: "Would you like to edit other user?",
					EDIT_FIRST_BUTTON: "Yes, edit another user",
					EDIT_SECOND_BUTTON: "No, edit only one usuário",
				},
			},
			STATEMENT: {
				TITLE_PAGE: "Statement",
				FILTER_BY: "Filter by period",
				COMPANY: "Filter by client",
				COMPANY_PLACEHOLDER: "Select a client",
				BUTTON_CSV: "Export as CSV",
				LABEL_EXECUTIONS: "Executions from",
				PERIOD: "Period",
				LIST: "List",
				REQUEST_BY: "Requested By",
				EXECUTIONS: "Executions",
				LIST_EMPTY: "This list seems to be empty. Please select a month from the dropdown list to display information.",
				EXECUTION: {
					EXECUTIONS: " Executions ",
					FROM: "from",
					LIST: "List",
				},
				MONTH_DAY_START: "From ",
				MONTH_DAY_END: " until ",
				YEAR_TITLE_LABEL: " of "
			}
		}
	}
};

export { messages }