import styled from "styled-components";

export const Wrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	padding-left: 60px;
	padding-right: 60px;

	.us-region-flag {
		flex: 1;
	}

	.br-region-flag {
		flex: 1;
	}

	.inactive {
		opacity: 0.5
	}
`