import { Select } from "@mui/material";
import styled from "styled-components";

export const Label = styled.label`
    color: var(---primary-kroll-dark-font);
    font-weight: bold;
    margin-bottom: 6px;
`

export const Dropdown = styled(Select)`
    width: 288px;
    height: 56px;
    background-color: var(---primary-font-color);

    :hover{
        fieldset{
            border: 1px solid #E5E5E5;
        }
    }

    fieldset{
        border: 1px solid #E5E5E5 !important;
    }
`