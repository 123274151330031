import styled from "styled-components";
import TableContainer from '@mui/material/TableContainer';
import { TableCell } from "@mui/material";


export const WrapperTrable = styled(TableContainer)`
	width: 798px;
	height: 491px;
`;

export const TableCellWrapper = styled(TableCell)`
	border: 1px solid rgba(224, 224, 224, 1);
`;

export const TableCellSmaller = styled(TableCell)`
	width: 154px;
	border-right: none;
	&.MuiTableCell-body{
		color: #BF0D3E;
		font-weight: bold;
	}
`;

