import React from 'react';

// Styles
import { Wrapper, Input, Label } from './checkbox.styled';

function Checkbox({ label, checked, onClick }) {

	return (
		<Wrapper onClick={onClick}>
			<Input
				type="checkbox"
				checked={checked}
				onChange={onClick}
			/>
			<Label>{label}</Label>
		</Wrapper>
	);
}

export default Checkbox;