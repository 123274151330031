import * as React from 'react';
import Modal from '@mui/material/Modal';

import { BoxWrapper, ResetedImage, ResetImage } from './modal-reset-password.styled';

import resetPasswordImage from '../../../assets/resetPassword.svg';
import passwordReseted from '../../../assets/passwordReseted.svg';
import Button from 'components/button/Button';
import TextField from '@mui/material/TextField';
import InputPassword from './input-password-modal';

import { useState, useEffect } from 'react';
import { i18n } from 'translate/i18n';
import { resetPasswordCheckCode, resetPasswordEmail, resetPasswordNewPassword } from 'services/authentication';
import { useTranslation } from 'react-i18next';

export default function ModalResetPassword({
	isOpen,
	handleClose,
	firstAccess,
}) {
	const [step, setStep] = useState(1)
	const [timer, setTimer] = useState(0)
	const [activeTimer, setActiveTimer] = useState(false)
	const [values, setValues] = useState({
		email: '',
		code: '',
		password: '',
		confirmPassword: ''
	})

	const { t } = useTranslation();

	const validateEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

	const handleChange = (e) => {
		e.preventDefault();
		const newValues = { ...values }
		newValues[e.target.name] = e.target.value
		setValues(newValues)
	}

	const handleSendEmail = async () => {
		const result = await resetPasswordEmail(values.email)
		if (result.codeVerify) setStep(2)
	}

	useEffect(() => {
		let interval = null;
		if (activeTimer && timer > 0) {
			interval = setInterval(() => {
				setTimer(timer => timer - 1);
			}, 1000);
		} else if (activeTimer && timer === 0) {
			setActiveTimer(false)
			clearInterval(interval)
		};
		return () => clearInterval(interval)
	}, [activeTimer, timer]);

	const handleCheckCode = async () => {
		const result = await resetPasswordCheckCode(values.email, values.code)
		if (result.codeVerify) setStep(3)
	}

	useEffect(() => {

		const passSearchAcessData = () => {
			setValues({
				email: firstAccess.email,
				code: firstAccess.code,
				password: '',
				confirmPassword: ''
			})
		}

		const checkCodeAndSkipSteps = async () => {
			const result = await resetPasswordCheckCode(firstAccess.email, firstAccess.code)
			if (result.codeVerify) { setStep(3) } else {
				handleClose();
			}
		}

		if (firstAccess.email && firstAccess.code) {
			passSearchAcessData();
			checkCodeAndSkipSteps();
		}
	}, [firstAccess.email, firstAccess.code])

	const handleResendEmail = async () => {
		if (!activeTimer) {
			const result = await resetPasswordEmail(values.email)
			if (result.codeVerify) {
				setTimer(59)
				setActiveTimer(true)
			}
		}
	}

	const handleResetPassword = async () => {
		const { email, code, password, confirmPassword } = values
		if (email && code && password && confirmPassword && (password === confirmPassword)) {
			const result = await resetPasswordNewPassword(email, code, password)
			if (result.item) setStep(4)
		}
	}

	const showTimer = () => {
		const seconds = timer < 10 ? `0${timer}` : timer
		return ` 0:${seconds}...`
	}

	return (
		<>
			<Modal
				open={isOpen}
				onClose={handleClose}
			>
				<BoxWrapper>
					<ResetImage src={resetPasswordImage} alt="reset-password" style={{ display: step === 4 ? 'none' : 'block' }} />
					<ResetedImage src={passwordReseted} alt="password-reseted" className="finished" style={{ display: step !== 4 ? 'none' : 'block' }} />
					{step === 1 && (
						<>
							<h1>{t('FORGOT_PASSWORD.EMAIL.TITLE')}</h1>
							<p>{t('FORGOT_PASSWORD.EMAIL.BODY')}</p>

							<TextField placeholder={t('FORGOT_PASSWORD.EMAIL.INPUT_PLACEHOLDER')} onChange={handleChange} type="email" value={values.email} name="email" />
							<Button
								buttonContent={t("FORGOT_PASSWORD.EMAIL.SUBMIT_BUTTON")}
								widthProp={"438px"}
								disabledProp={!validateEmail.test(values.email)}
								funcButton={() => handleSendEmail()} />
						</>
					)}
					{step === 2 && (
						<>
							<h1>{t('FORGOT_PASSWORD.CODE.TITLE')}</h1>
							<p>{t('FORGOT_PASSWORD.CODE.BODY')}</p>

							<TextField placeholder={t('FORGOT_PASSWORD.CODE.INPUT_PLACEHOLDER')} onChange={handleChange} value={values.code} name="code" />
							<Button
								buttonContent={t("FORGOT_PASSWORD.CODE.SUBMIT_BUTTON")}
								widthProp={"438px"}
								disabledProp={values.code.length < 4}
								funcButton={() => handleCheckCode()} />
							<span className="resend-code" onClick={handleResendEmail}>
								{
									activeTimer ?
										t('FORGOT_PASSWORD.CODE.NEW_CODE_SENDED') + showTimer()
										: t('FORGOT_PASSWORD.CODE.NEW_CODE')
								}
							</span>
						</>
					)}
					{step === 3 && (
						<>
							<h1>
								{
									firstAccess.email
										? t('FORGOT_PASSWORD.PASSWORD.TITLE_FIRST_ACCESS')
										: t('FORGOT_PASSWORD.PASSWORD.TITLE')}
							</h1>
							<p>{t('FORGOT_PASSWORD.PASSWORD.BODY')}</p>

							<InputPassword
								placeholder={t("FORGOT_PASSWORD.PASSWORD.PASSWORD_PLACEHOLDER")}
								value={values.password}
								name="password"
								onChange={handleChange}
							/>
							<InputPassword
								placeholder={t("FORGOT_PASSWORD.PASSWORD.CONFIRM_PASSWORD_PLACEHOLDER")}
								value={values.confirmPassword}
								name="confirmPassword"
								onChange={handleChange}
							/>
							<Button
								buttonContent={t("FORGOT_PASSWORD.PASSWORD.SUBMIT_BUTTON")}
								widthProp={"438px"}
								disabledProp={values.password !== values.confirmPassword}
								funcButton={() => handleResetPassword()} />
						</>
					)}
					{step === 4 && (
						<>
							<h1>{t('FORGOT_PASSWORD.RESETED.TITLE')}</h1>
							<p>{t('FORGOT_PASSWORD.RESETED.BODY')}</p>

							<Button
								buttonContent={t("FORGOT_PASSWORD.RESETED.SUBMIT_BUTTON")}
								widthProp={"438px"}
								funcButton={() => handleClose()} />
						</>
					)}
				</BoxWrapper>
			</Modal>
		</>
	);
}