import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import { Wrapper, WrapperSelect, Label, FormWrapper } from './dropdown-user.styled';

const MenuProps = {
  MenuListProps: {
    style: {
      maxHeight: '260px',
    }
  },
};


export default function DropdownUser({ company, onChange, propRegister, name, register, errors, label, dropdownPlaceholder, style }) {

  return (
    <Wrapper style={style}>
      <FormWrapper>
        <Label>{label}</Label>
        <WrapperSelect
          input={<OutlinedInput />}
          variant={"outlined"}
          name={name}
          onChange={onChange}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
          {...register && register(propRegister)}
          defaultValue="defaultValue"
        >
          <MenuItem
            value="defaultValue"
            disabled
          >
            <p style={{
              color: "#4D4D4F",
              fontSize: "16px"
            }}>{dropdownPlaceholder}</p>
          </MenuItem>
          {company.map((item, index) => (
            <MenuItem
              value={item.id}
              key={index}
            >
              {item.name}
            </MenuItem>
          ))}
        </WrapperSelect>
      </FormWrapper>
      {errors?.message && <span>{errors?.message || ''}</span>}
    </Wrapper>
  );
}