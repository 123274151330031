import * as React from 'react';
import Modal from '@mui/material/Modal';
import { upload } from '../../services/home'

import { BoxWrapper, ImageItem, RadioWrapper } from './modal-upload.styled';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Alert from '../../components/alert/Alert'

import uploadIcon from '../../assets/upload.svg';
import Radio from '../../components/radio/Radio';
import Button from 'components/button/Button';
import TextField from '@mui/material/TextField';

import { useState } from 'react';
import { i18n } from 'translate/i18n';
import { setInExecution } from 'store/actions';
import { formatDate } from 'utils/date';
import { useTranslation } from 'react-i18next';

export default function ModalUpload({ isOpen, handleClose, onUpload }) {

	const [recurrenciesValues, setRecurrenciesValues] = useState("");
	const [openAlert, setOpenAlert] = useState(false);
	const [text, setText] = useState("");
	const [monthValue, setMonthValue] = useState("");
	const [selectedFile, setSelectedFile] = useState(null);
	const [timesListWillRun, setTimesListWillRun] = useState(2);
	const { t } = useTranslation();

	const handleGetFileUpload = (event) => {
		const file = event.target.files[0];
		setSelectedFile(file);
	}

	const handleUploadFile = () => {
		let formData = new FormData();
		formData.append('file', selectedFile);

		let objData = {};
		if (recurrenciesValues === "recurrence") {
			objData = {
				"recurrence": true,
				"period": monthValue,
				"ocurrence": timesListWillRun
			}
		}

		upload(formData, objData).then(data => {
			setInExecution(data)
			setText(`${t("HOME.FILE")} "${selectedFile.name}" ${t("HOME.UPLOAD_ALERT")}`);
			setOpenAlert(true)
			setSelectedFile(null);
			setTimeout(() => {
				handleClose()
				onUpload()
			}, "1000")
		});
	}

	const handleCalculateDate = (months) => {
		const currentDate = new Date();
		const monthDifference = months - 1;

		const calculateByRange = {
			"monthly": () => currentDate.setMonth(currentDate.getMonth() + parseInt(monthDifference)),
			"quarterly": () => currentDate.setMonth(currentDate.getMonth() + parseInt(monthDifference * 3)),
			"semiannual": () => currentDate.setMonth(currentDate.getMonth() + (parseInt(monthDifference) * 6)),
			// Corrigir
			"annually": () => currentDate.setFullYear(currentDate.getFullYear() + parseInt(monthDifference)),
		}

		const calculateDate = calculateByRange[monthValue] || (() => null);
		calculateDate();
		const formatedDate = formatDate(currentDate);
		return formatedDate;
	}

	return (
		<>
			<Alert open={openAlert} setOpen={setOpenAlert} type="success" text={text} />
			<Modal
				open={isOpen}
				onClose={handleClose}
			>
				<BoxWrapper>
					<ImageItem src={uploadIcon} alt="upload" className='teste' />
					<p>{t("HOME.MODAL.LABEL_FIRST_TEXT")}</p>

					<RadioWrapper>
						<Radio
							label={t("HOME.MODAL.RADIO_RUN_ONLY_ONCE")}
							nameValue={"recurrence-value"}
							valueState={"runOnlyOnce"}
							onChangeFunction={(e) => setRecurrenciesValues(e.target.value)}
						/>

						<Radio
							label={t("HOME.MODAL.RECURRENCE")}
							nameValue={"recurrence-value"}
							valueState={"recurrence"}
							onChangeFunction={(e) => setRecurrenciesValues(e.target.value)}
						/>
						{

							recurrenciesValues === "recurrence" &&
							<>
								<div className='radioInput'>
									<Radio
										label={t("HOME.MODAL.RECURRENCE_MONTH")}
										nameValue={"timeValue"}
										valueState={"monthly"}
										onChangeFunction={(e) => setMonthValue(e.target.value)}
									/>
									<Radio
										label={t("HOME.MODAL.RECURRENCE_QUARTERLY")}
										nameValue={"timeValue"}
										valueState={"quarterly"}
										onChangeFunction={(e) => setMonthValue(e.target.value)}
									/>

									<Radio
										label={t("HOME.MODAL.RECURRENCE_SEMI")}
										nameValue={"timeValue"}
										valueState={"semiannual"}
										onChangeFunction={(e) => setMonthValue(e.target.value)}
									/>

									<Radio
										label={t("HOME.MODAL.RECURRENCE_ANN")}
										nameValue={"timeValue"}
										valueState={"annually"}
										onChangeFunction={(e) => setMonthValue(e.target.value)}
									/>
								</div>
								<div className="timesWilRun">
									<p>{t("HOME.MODAL.HOW_MANY_TIMES_RUN")}</p>
									<TextField
										id="outlined-number"
										type="number"
										InputProps={{ inputProps: { min: 2 } }}
										InputLabelProps={{
											shrink: true,
										}}
										onBlur={(e) => setTimesListWillRun(e.target.value < 2 ? 2 : e.target.value)}
										onChange={(e) => setTimesListWillRun(e.target.value)}
										value={timesListWillRun}
									/>
								</div>
								<div className='listWillRun'>
									<p>{t("HOME.MODAL.WILL_RUN_UNTIL")} {handleCalculateDate(timesListWillRun)}</p>
								</div>
							</>
						}
					</RadioWrapper>
					<p>
						{t("HOME.MODAL.SECOND_lABEL_FIRST")}
					</p>

					<>
						<input type="file" id="file" accept=".xlsx" onChange={handleGetFileUpload} />
						<label htmlFor="file" className='labelFileUpload'>
							<p>{selectedFile ? selectedFile.name : t("HOME.MODAL.SELECT_FILE")}</p>
							<p className='iconButton'>
								<FileUploadIcon fontSize='medium' />
							</p>
						</label>
					</>

					<Button
						buttonContent={t("HOME.MODAL.SUBMIT_BUTTON")}
						widthProp={"438px"}
						disabledProp={!(selectedFile && recurrenciesValues)}
						funcButton={() => handleUploadFile()} />
				</BoxWrapper>
			</Modal>
		</>
	);
}