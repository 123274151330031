import styled from "styled-components";

export const Wrapper = styled.div`
	svg{
		color: #ffff;
	};
	p{
		margin: 0 20px;
		color: #ffff;
	}
`;

export const MenuValue = styled.div`
	color: #ffff;
	margin: 0 auto;
`;