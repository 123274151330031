import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { ColorsStyle } from 'themes/color';
import { FontsSize } from 'themes/fonts';
import theme from 'themes/material';
import Routes from './routes/index';
import 'translate/i18n';

import Loader from 'components/loader/Loader';
import Alert from 'components/alert/Alert'
import { useEffect } from 'react';

function App() {
	const { alert, loader } = useSelector(state => state);
	return (
		<>
			<ThemeProvider theme={theme}>
				<Loader {...loader} />
				<Alert {...alert} />
				<BrowserRouter>
					<Routes />
					<ColorsStyle />
					<FontsSize />
				</BrowserRouter>
			</ThemeProvider>
		</>
	);
}

export default App;
