import React from 'react';

// Styles
import { Wrapper, Input } from './checkbox-cell.styled';

function Checkbox({ checked, onChange }) {

	return (
		<Wrapper>
			<Input
				type="checkbox"
				checked={checked}
				onChange={onChange}
			/>
		</Wrapper>
	);
}

export default Checkbox;