const hasFound = (index) => index > -1

const createTypeObject = ({ total, type, category }) => {
  return {
    type,
    category,
    id: `${category}_${type}`,
    name: type,
    qty: Number(total),
    selected: false
  }
}

const createCategoryObject = (flag) => {
  const { category, total } = flag

  return {
    category,
    id: category,
    name: category,
    qty: Number(total),
    selectedAll: false,
    types: [
      createTypeObject(flag)
    ]
  }
}

const groupByCategory = (groups, flag) => {
  const categoryIndex = groups.findIndex(item => item.category === flag.category)

  if (hasFound(categoryIndex)) {
    groups[categoryIndex].qty += Number(flag.total)
    const typeIndex = groups[categoryIndex].types.findIndex(item => item.type === flag.type)

    if (hasFound(typeIndex)) {
      groups[categoryIndex].types[typeIndex].qty += Number(flag.total)
    } else {
      groups[categoryIndex].types.push(createTypeObject(flag))
    }
  } else {
    groups.push(createCategoryObject(flag))
  }

  return groups
}

const sumTermsWithFlagsQty = (qty, term) => term.length > 0 ? qty + 1 : qty
const sumFlagsQty = (sum, flag) => sum += Number(flag.qty)
const sortByCategoryFlagsQtyDesc = (category1, category2) => Number(category2.qty) - Number(category1.qty)
const sumTermsFlagsQty = (qty, flag) => qty + Number(flag.total)

const regexEspecialCharacters = /[^a-zA-Z0-9]/g;

const filterTermsByNameOrDocument = (terms, filter) => {
  return terms.filter(term => (
    term.name.toLowerCase().includes(filter.toLowerCase())) ||
    term.document.number.toLowerCase()
      .includes(filter.toLowerCase().replace(regexEspecialCharacters, ''))
  )
}

export const processCategories = (data, filter = '') => {
  let { terms } = data
  if (filter !== '') terms = filterTermsByNameOrDocument(terms, filter)

  const flagsArray = terms.length > 0 ?
    terms.map((term) => term.flags) :
    data.terms.map((term) => (term.flags.map((flag) => ({ ...flag, total: 0 }))))

  const flags = flagsArray.flat().reduce(groupByCategory, [])

  return {
    flags
  }
}

export const processData = (data) => {
  let { terms } = data
  const flagsArray = terms.map((term) => term.flags)
  const redFlagTerms = flagsArray.length > 0 ? flagsArray.reduce(sumTermsWithFlagsQty, 0) : []
  const flags = flagsArray.length > 0 ? flagsArray.flat().reduce(groupByCategory, []) : []
  const totalRedFlags = flags.length > 0 ? flags.reduce(sumFlagsQty, 0) : 0
  const mostRedFlagsCategory = flags.length > 0 ? flags.sort(sortByCategoryFlagsQtyDesc)[0] : { category: 'Nenhuma', qty: 0 }
  const { category, qty } = mostRedFlagsCategory
  const percentage = qty > 0 ? Math.round((qty * 100) / totalRedFlags) : 0
  terms = terms.map(item => { return { ...item, totalFlags: item.flags.reduce(sumTermsFlagsQty, 0), selected: false } })

  return {
    summary: {
      totalRedFlags,
      redFlagTerms,
      totalTerms: terms.length,
      mostRedFlagsCategory: {
        name: category,
        category,
        percentage
      },
    },
    flags,
    terms
  }
}