import React, { useState, useEffect } from 'react';
import { WrapperSearch, WrapperMenu, Container } from './home.styled';

//Components
import Dropdown from 'components/dropdown/Dropdown';
import Sidebar from 'components/sidebar/Sidebar';
import Button from 'components/button/Button';
import InputSearch from '../../components/input/input-search/Search-Input';
import ListContainer from 'components/list-container/List-Container';
import Alert from '../../components/alert/Alert'



//icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';


//images
import ModalUpload from './../../components/modal/Modal-Upload';
import FilerSidebar from 'components/filter-sidebar/Filter-sidebar';
import ModalExit from 'components/modal/modal-exit/Modal-exit';


import { i18n } from "../../translate/i18n";
import { getExecutedLists, getOnHoldLists, getRunningLists } from 'services/home';
import ModalFaq from 'components/modal/modal-faq/modal-faq';
import Card from 'components/card/Card';
import Wrapper from './../../components/wrapper/Wrapper';
import { useTranslation } from 'react-i18next';

function Home() {
	const [listExecuted, setListExecuted] = useState([]);
	const [listOnExecution, setListOnExecution] = useState([]);
	const [listInPlanning, setListInPlanning] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const [filterSidebar, setFilterSidebar] = useState(false);
	const { t } = useTranslation();

	const [openAlert, setOpenAlert] = useState(false);
	const [text, setText] = useState('');
	const [exitModalOpen, setExitModalOpen] = useState(false);
	const [showFaqModal, setShowFaqModal] = useState(false)

	useEffect(() => {
		loadData()
	}, [])

	const loadData = async () => {
		setListExecuted(await getExecutedLists())
		setListOnExecution(await getRunningLists())
		setListInPlanning(await getOnHoldLists())
	}

	const handleDownloadTemplate = (nameTemplate) => {
		let uri = process.env.REACT_APP_URL_TEMPLATE_INDIVIDUAL || 'https://krollstorageaccounnt.blob.core.windows.net/krollupload/Model%20CPF%20and%20CNPJ%20DD/template_lista_CPF.xlsx?sp=r&st=2023-02-10T14:09:39Z&se=2030-02-10T22:09:39Z&spr=https&sv=2021-06-08&sr=b&sig=HrPW7rD7A5Wdb2Jy7BiwiG7FSWz0DFq9jiEeNp3sK08%3D'

		if (nameTemplate === t("HOME.COMPANY")) {
			uri = process.env.REACT_APP_URL_TEMPLATE_COMPANY || 'https://krollstorageaccounnt.blob.core.windows.net/krollupload/Model%20CPF%20and%20CNPJ%20DD/template_lista_CNPJ.xlsx?sp=r&st=2023-02-10T14:07:47Z&se=2030-02-10T22:07:47Z&spr=https&sv=2021-06-08&sr=b&sig=WiLGFaYNECvw9I5ixRd9XOkSnqwtmK%2F4b97yXxn1VhE%3D'
		}

		const link = document.createElement("a");
		link.download = 'nome';
		link.href = uri;
		link.click();

		setText(`Template "${nameTemplate}" ${t("HOME.DOWNLOAD_ALERT")}`);
		setTimeout(() => {
			setOpenAlert(true)
		}, "1000")
	}

	const handleApplyFilter = () => {
		setText(t("HOME.FILTERS_APPLIED"));
		setTimeout(() => {
			setOpenAlert(true)
			setFilterSidebar(false)
		}, "1000")
	}

	const executedColumns = [
		{ label: "HOME.LISTS.CLIENT_NAME", name: 'clientName' },
		{ label: "HOME.LISTS.NAME_LIST", name: 'listName' },
		{ label: "HOME.LISTS.CATEGORY", name: 'category' },
		{ label: "HOME.LISTS.SUBJECTS", name: 'totalTerms' },
		{ label: "HOME.LISTS.USER_NAME", name: 'userName' },
		{ label: "HOME.LISTS.REQUEST_DATE", name: 'dateRequest', type: 'date' },
		{ label: "HOME.LISTS.FINISH_DATE", name: 'dateExecution', type: 'date' },
		{ label: '', name: 'statusFlag', type: 'executed' }
	]

	const onExecutionColumns = [
		{ label: "HOME.LISTS.CLIENT_NAME", name: 'clientName' },
		{ label: "HOME.LISTS.NAME_LIST", name: 'listName' },
		{ label: "HOME.LISTS.CATEGORY", name: 'category' },
		{ label: "HOME.LISTS.SUBJECTS", name: 'totalTerms' },
		{ label: "HOME.LISTS.USER_NAME", name: 'userName' },
		{ label: "HOME.LISTS.REQUEST_DATE", name: 'dateRequest', type: 'date' },
		{ label: "", name: '', type: 'in execution' },
	]

	const executionQueueColumns = [
		{ label: "HOME.LISTS.CLIENT_NAME", name: 'clientName' },
		{ label: "HOME.LISTS.NAME_LIST", name: 'listName' },
		{ label: "HOME.LISTS.CATEGORY", name: 'category' },
		{ label: "HOME.LISTS.SUBJECTS", name: 'totalTerms' },
		{ label: "HOME.LISTS.USER_NAME", name: 'userName' },
		{ label: "HOME.LISTS.REQUEST_DATE", name: 'dateRequest', type: 'date' },
		{ label: '', name: 'recurrence', type: 'on hold' }
	]

	return (
		<Wrapper>
			<Container>
				{/* <FilerSidebar isOpen={filterSidebar} handleClose={() => setFilterSidebar(false)} applyFilters={handleApplyFilter} /> */}
				<ModalUpload
					isOpen={isOpen}
					handleClose={() => setIsOpen(false)}
					onUpload={loadData} />

				<ModalExit
					isOpen={exitModalOpen}
					handleClose={() => setExitModalOpen(false)}
				/>

				<ModalFaq isOpen={showFaqModal} handleClose={() => setShowFaqModal(false)} />

				<Alert open={openAlert} setOpen={setOpenAlert} type="success" text={text} />
				{/* <WrapperSearch>
					<InputSearch placeholder={t("HOME.SEARCH_INPUT_PLACEHOLDER")} />
					<FilterAltOutlinedIcon className='filterButton' onClick={() => setFilterSidebar(true)} />
				</WrapperSearch> */}

				<WrapperMenu>
					<span className='tittleWrapper'>
						<h2>{t("HOME.LIST_LABEL")}</h2>
						<InfoOutlinedIcon onClick={() => setShowFaqModal(true)} style={{ cursor: 'pointer' }} />
					</span>
					<Dropdown
						dropdownLabel={t("Download")}
						values={[t("HOME.INDIVIDUAL"), t("HOME.COMPANY")]}
						handleDownloadTemplate={handleDownloadTemplate}
					/>

					<Button buttonType={'button'} funcButton={() => setIsOpen(true)}>
						<span className='btnUpload'>
							<FileUploadOutlinedIcon />
							<p>{t("HOME.UPLOAD_NEW_LIST")}</p>
						</span>
					</Button>
				</WrapperMenu>

				<ListContainer title={`${t("HOME.EXECUTED")} (${listExecuted.length})`} data={listExecuted} columns={executedColumns} />
				<ListContainer title={`${t("HOME.ON_EXECUTION")} (${listOnExecution.length})`} data={listOnExecution} columns={onExecutionColumns} />
				<ListContainer title={`${t("HOME.EXECUTION_QUEUE")} (${listInPlanning.length})`} data={listInPlanning} columns={executionQueueColumns} />
			</Container>
		</Wrapper >
	);
}

export default Home;