import { Timeline } from "@mui/lab";
import styled from "styled-components";
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';


export const WrapperLine = styled(Timeline)`
    &.MuiTimeline-root{
        padding: 0 16px;
    }
`;

export const WrapperTimelineConnector = styled(TimelineConnector)`
    &.MuiTimelineConnector-root{
        background-color: var(---primary-kroll);
    }
`;

export const WrapperTimelineDot = styled(TimelineDot)`
    &.MuiTimelineDot-root{
        background-color: var(---primary-kroll-green);
        margin: 0;
    }
`;
