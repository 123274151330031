import { Route, Routes as RoutesAll, Navigate } from 'react-router-dom'
import PrivateRoutes from './PrivateRoutes';
import Login from 'pages/Login/Login'
import Home from 'pages/Home/Home';
import PreHome from './../pages/Pre-Home/Pre-Home';
import ListDetail from 'pages/List-Detail/List-Detail';
import Client from 'pages/Client/Client';
import User from 'pages/User/User';
import Statment from 'pages/Statment/Statment';

export default function Routes() {
	return (
		<RoutesAll forceRefresh>
			<Route path="/" exact element={<Login />} />
			<Route path="/" element={<PrivateRoutes />}>
				<Route path="/" element={<Navigate replace to="pre-home" />} />
				<Route path="pre-home" element={<PreHome />} />
				<Route path="home" element={<Home />} />
				<Route path="list-detail" element={<ListDetail />} />
				<Route path="client" element={<Client key={'new'} />} />
				<Route path="client/edit" element={<Client key={'edit'} isUpdate={true} />} />
				<Route path="user" element={<User key={'new'}/>} />
				<Route path="user/edit" element={<User key={'edit'} isUpdate={true} />} />
				<Route path="statment" element={<Statment />} />
			</Route>
		</RoutesAll>

	);
}