import React from 'react'

//Styles
import { Wrapper } from './InputClient.styled'

const Input = (props) => {
	const { name, labelInput, type, placeholder, value, onChange, required, register, errors, disabled } = props

	return (
		<Wrapper>
			<label>{labelInput}</label>
			<input
				type={type}
				name={name}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				required={required}
				disabled={disabled}
				{...register(name)}
			/>
			{errors?.message && <span>{errors?.message || ''}</span>}
		</Wrapper>
	)
}

export default Input